<template>
  <modal-layout @close="$emit('close')" title="Каско лайт">
    <div class="p-3">
      <h2 class="fw-bold">Оформите КАСКО всего за 1 минуту!</h2>
      <h4 class="fw-bold">Приобретая полис КАСКО, Вы получаете защиту своего авто внезависимости от вины при ДТП! Мы убережем Вас от отсутствия страховки у другого водителя.</h4>
      <p>
        Предлагаем Вам идеальное дополнение к обязательному автострахованию «КАСКО лайт online».
        При страховом случае мы Вам выплатим до:
      </p>
      <ul class="mb-2">
        <li>- 100.000 тенге (стоимость 6.000 ₸)</li>
        <li>- 200.000 тенге (стоимость 12.000 ₸)</li>
        <li>- 300.000 тенге (стоимость 18.000 ₸)</li>
<!--        <li>- 500.000 тенге (стоимость 28.000 ₸)</li>-->
      </ul>
      <p>
        КАСКО лайт создается автоматически по первому застрахованному автомобилю и первым трем застрахованным водителям, указанным в страховке ОГПО ВТС.
      </p>
    </div>
    <button class="primary-btn bottom-page-btn" @click="$emit('close')">Вернуться</button>
  </modal-layout>
</template>

<script>
export default {
  name: "CascoLiteModal"
}
</script>

<style scoped>

</style>
