<template>
  <div class="wrapper">
    <v-dialog/>
    <ValidationObserver ref="form" v-slot="{ invalid, errors }">
      <input-field
          v-model="form.phone_number"
          :addition="$t('cantChangeNumber')"
          :disabled="true"
          :is-block="true"
          :label="$t('phoneNumber')"
          field-type="tel"
          mask="+7(###) ###-##-##"
          name="phone"
          rules="required|numeric|min:10"
      />

      <div>
        <single-check-box :label="$t('travelIncludeCovid')" v-model="isCovidProtect"/>
      </div>

      <div>

        <radio-input-list
            v-if="formStepValue > 0"
            :addition="travelTypeAdditionText"
            :options="travel_types"
            :title="$t('typeOfTrip')"
            :value="form.is_multiple"
            radio-name="type-of-travel"
            @input="changeTravelType"
        />

        <search-select
            :searchable="false"
            v-if="!travelIsMultiple"
            ref="cover_types"
            @input="selectPacket"
            v-model="packet"
            :addition="singleTravelTypeText"
            :is-block="true"
            :options="programs"
            :placeholder="$t('selectPackage')"
            :reduce="p => p.id"
            label="name"
            name="packet"
            rules="required"
            track-by="value"
        />

        <template v-if="formStepValue > 1">
          <add-country
              :addBtnDisabled="countryAddBtnDisabled"
              :button-title="$t('addCountry')"
              :countries="form.countries"
              :countriesList="remainingCountries"
              @delete="deleteCountry"
              @input="addCountry"
          />
        </template>

        <template v-if="formStepValue > 2">
          <search-select
              :searchable="false"
              v-model="insuredSum"
              :is-block="true"
              :options="amounts"
              :placeholder="$t('selectInsuranceSum')"
              label="name"
              name="insuranceSum"
              rules="required"
              track-by="value"
          />
        </template>


        <template v-if="formStepValue > 3">
          <select-date
              v-model="form.start_date"
              :is-block="true"
              @input="selectDate"
              :is-disabled-before-today="true"
              :until="untilTomorrow"
              :title="$t('departureDate')"
          />
          <select-date
              v-if="!travelIsEducational && !travelIsMultiple"
              v-model="form.end_date"
              @input="selectDate"
              :is-block="true"
              :is-disabled-before-today="true"
              :title="$t('arrivalDate')"
              :until="minEndDate"
          />
        </template>

        <search-select
            v-if="(travelIsEducational && form.cover_value) || travelIsMultiple"
            :searchable="false"
            v-model="form.period"
            @input="selectDate"
            :is-block="true"
            :options="insurancePeriods"
            :placeholder="$t('insurancePeriod')"
            :reduce="ip => ip.value"
            label="name"
            name="insurancePeriods"
            rules="required"
            track-by="value"
        />
        <search-select
            v-if="form.period && travelIsMultiple"
            v-model="form.duration"
            @input="selectDate"
            :is-block="true"
            :options="insuranceDurations"
            :placeholder="$t('tripDuration')"
            :reduce="d => d.value"
            label="name"
            name="durationOfTravel"
            rules="required"
            track-by="value"
        />

        <search-select
            v-if="showFranchiseField"
            v-model="form.franchise"
            @input="selectDate"
            :is-block="true"
            :options="franchiseOptions"
            :placeholder="$t('franchise')"
            :reduce="f => f.value"
            label="name"
            name="franchiseOption"
            rules="required"
            track-by="value"
        />

        <single-check-box
            v-if="((form.start_date && form.end_date) || (form.start_date && form.period)) && travelIsEducational"
            :label="$t('isStudentAthlete')"
            v-model="isAthlete"
        />
        <search-select
            v-if="isAthlete || travelIsAthletic"
            v-model="form.sport"
            @input="selectDate"
            :is-block="true"
            :options="sports"
            :placeholder="$t('sportType')"
            :reduce="s => s.value"
            label="name"
            track-by="value"
        />
<!--        <single-check-box-->
<!--            v-if="(form.start_date && form.end_date) || (form.start_date && form.period)"-->
<!--            :label="$t('alreadyInCountryOfTempResidence')"-->
<!--            v-model="alreadyInCountry"-->
<!--        />-->

        <div v-if="((form.start_date && form.end_date) || (form.start_date && form.period)) && alreadyInCountry" class="addition-invalid">
          <icon class="me-2" icon-name="read-info-icon"></icon>
          <span>{{ $t('insuranceCountryWarning') }}</span>
        </div>

        <template v-if="formStepValue > 5">
          <div>
            <TouristInfo :value="form.holder" @changed="changeHolder" :key="form.holder.uuid"/>

            <single-check-box :label="$t('policyHolderTheInsured')" v-model="form.owner_is_holder"/>
          </div>
          <template v-for="(insured, index) in form.insureds">
            <TouristInfo
                :key="`insured_${insured.uuid}`"
                :can-delete="!!form.insureds.length"
                :index="index"
                :title="touristInfoTitle(insured.uuid)"
                :value="insured"
                rules="required"
                @changed="changeTourist"
                @error="onError"
                @removeItem="removeTourist"
            />
          </template>

          <div
              v-if="canAddTourist"
              class="primary-block"
              @click="addTourist"
          >
            <div class="input-add-id">
              <div class="icon-box">
                <icon icon-name="plus-icon"></icon>
              </div>
              <span class="text">{{ $t('addTourist') }}</span>
            </div>
          </div>
        </template>

        <box-product-bonuses v-if="formStepValue >= 7" :available-products="availableBonuses" v-model="bonusProduct"/>

        <div v-if="formStepValue >= 7" class="final-amount-wrapper">
          <final-amount
              :bonus-title="bonusTitle"
              :bonus-value="bonusValue"
              :amount="amount"
              :amount-loaded="amountLoaded"
              :fix-sum="amount"
              :insured-sum="insuredSum.name"
              :invalid="invalid"
              @submit="onSubmit"
          />
        </div>
      </div>
    </ValidationObserver>
    <swipe-modal
        v-model="isModal"
        contents-height="50vh"
        border-top-radius="16px"
        :persistent="true"
        backgroundColor="#F7F7F7"
        tip-color="#c8c8c8"
        contents-color="#F7F7F7"
    >
      <sos-feedback></sos-feedback>
    </swipe-modal>
  </div>
</template>

<script>
import SearchSelect from "../../BuyPolicy/SearchSelect";
import SelectDate from "../../BuyPolicy/SelectDate";
import RadioInputList from "../../BuyPolicy/RadioInputList";
import InputField from "../../BuyPolicy/InputField";
import SingleCheckBox from "../../BuyPolicy/SingleCheckBox";
import FinalAmount from "../../BuyPolicy/FinalAmount";
import AddCountry from "../../BuyPolicy/AddCountry";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {travelerCalculation} from "../../../api/calculations";
import eventLogger from "../../../services/eventLogger";
import ePayPayment from "../../../mixin/ePayPayment";
import {uuidv4} from "../../../plugins/Helpers";
import redmine from "../../../mixin/redmine";
import TouristInfo from "../../TouristInfo";
import travelForm from "../../../mixin/travelForm";
import _ from "lodash";
import moment from "moment";
import BoxProductBonuses from "../../BuyPolicy/BoxProductBonuses";
import bonuses from "../../../mixin/bonuses";
import CountrySelectModal from "../../Modals/CountrySelectModal";
import swipeModal from "@takuma-ru/vue-swipe-modal";
import SosFeedback from "../../../views/SosFeedback";
// import DateRangePicker from 'vue2-daterange-picker'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const switchPrograms = {
  "SND_TRA_OL" : "SND_OL_AC",
  "BST_TRA_OL" : "BST_OL_AC",
  "KKP_SPO_OL" : "SPR_OL_AC",
  "KIS_EDU_OL" : "EDU_OL_AC",
  "KIS_ESP_OL" : "ESP_OL_AC",
  "KKP_MUL_OL" : "MUL_OL_AC"
}

const switchCovidPrograms = {
  "SND_OL_AC" : "SND_TRA_OL",
  "BST_OL_AC" : "BST_TRA_OL",
  "SPR_OL_AC" : "KKP_SPO_OL",
  "EDU_OL_AC" : "KIS_EDU_OL",
  "ESP_OL_AC" : "KIS_ESP_OL",
  "MUL_OL_AC" : "KKP_MUL_OL"
}

export default {
  name: "TravelerForm",
  mixins: [ePayPayment, redmine, travelForm],
  components: {
    BoxProductBonuses,
    SingleCheckBox,
    TouristInfo,
    SearchSelect,
    SelectDate,
    RadioInputList,
    InputField,
    FinalAmount,
    AddCountry,
    swipeModal,
    SosFeedback
    // DateRangePicker
  },
  data() {
    return {
      isModal:false,
      amount: 0,
      packet: null,
      isCovidProtect: false,
      isAthlete: false,
      alreadyInCountry: false,
      dateRange: {
        start_date: null,
        end_date: null
      },
      form: {
        draft_id: uuidv4(),
        phone_number: null,
        insurance_type: null,
        owner_is_holder: true,
        holder: {
          uuid: null,
          individual_id_number: null,
          gender: null,
          doc_type: null,
          doc_number: null,
          doc_issue_date: null,
          address_region: null,
          address_state: null,
          address_city: null,
          address_street_full: null,
          is_foreigner: false,
          birth_date: null,
          last_name: null,
          first_name: null,
          full_name: null,
          documents: [],
          error: null,
          country: null,
        },
        is_multiple: false,
        cover_value: null,
        cover_type: null,
        start_date: null,
        end_date: null,
        period: null,
        duration: null,
        sport: null,
        currency: null,
        franchise: null,
        countries: [],
        insureds: [],
        bonus_id: null
      },
      amountWrapperLoaded: false,
      amountLoaded: false
    }
  },
  created() {
    eventLogger('view_traveler_screen');

    this.debouncedSaveDraft = _.debounce(this.saveDraft, 600)
    this.debouncedGetCalculation = _.debounce(this.getCalculation, 600)

  },

  async mounted() {
    const loading = this.$loading.show();



    try {
      await this.setDefaultInfo();
      await this.getCityList()
      await this.getCountryList();
      await this.getTravelerDictionary(0);
    } catch (e) {
      const {data} = e.response;
      await this.sendErrorsToRedmine(data);
    } finally {
      loading.hide()
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'authUser',
      listCities: 'dictionary/cityList',
      travelerDictionary: 'dictionary/traveler',
      product: 'INSURANCE_PRODUCT',
      formFinal: 'travelerForm/finalFormValue',
      packetFinal: 'travelerForm/packet',
      amountFinal: 'travelerForm/amount',
    }),

    bonusProduct: {
      get() {
        return this.form.bonus_id;
      },
      set(payload) {
        this.form.bonus_id = payload
      }
    },
    finalAmount() {
      return this.amount
    },
    productId() {
      return this.isCovidProtect ? 10 : 5;
    },
    insuranceType () {
      return this.isCovidProtect ? '9103' : '9100';
    },
    travelIsEducational () {
      return (this.isCovidProtect && Number(this.packet) === 11) || Number(this.packet) === 4
    },
    travelIsAthletic () {
      return (this.isCovidProtect && Number(this.packet) === 10) || Number(this.packet) === 3
    },
    educationProgram () {
      return this.isCovidProtect ? "EDU_OL_AC" : "KIS_EDU_OL"
    },
    educationSportProgram() {
      return this.isCovidProtect ? "ESP_OL_AC" : "KIS_ESP_OL" ;
    },
    educationPrograms () {
      return this.isCovidProtect ? ["EDU_OL_AC", "ESP_OL_AC"] : ["KIS_EDU_OL", "KIS_ESP_OL"];
    },
    sportPrograms () {
      return this.isCovidProtect ? ['SPR_OL_AC', 'ESP_OL_AC'] : ['KKP_SPO_OL', 'KIS_ESP_OL'];
    },
    multiProgram () {
      return this.isCovidProtect ? "MUL_OL_AC" : "KKP_MUL_OL"
    },
    programs () {
      return this.packets.filter((f) => f.product_id === this.productId && f.code !== this.educationSportProgram && f.code !== this.multiProgram);
    },
    showFranchiseField() {
      return (
          this.form.cover_type === "KKP_MUL_OL" && (this.form.currency !== "USD" || (this.form.period === 30 && this.form.duration === 10))
      );
    },
    formStepValue() {
      const { form, isAthlete, alreadyInCountry } = this
      const { cover_type, countries, cover_value, end_date, period, sport,  holder, insureds, duration } = form
      let step = 1;

      if (!cover_type) { return step; }
      step = 2;

      if (countries.length === 0) { return step; }
      if (countries.some(country => !country.code)) { return step }
      step = 3

      if (!cover_value) { return step; }
      step = 4

      if (!end_date) { return step }
      step = 5

      if (this.sportPrograms.includes(cover_type) && !sport) { return step }
      if (cover_type === this.multiProgram && !duration) { return step }
      if (this.educationPrograms.includes(cover_type) && !period) { return step }
      if (cover_type === this.educationSportProgram && !isAthlete) { return step }

      if(alreadyInCountry) { return step }

      step = 6;

      let holderIsValid = true;
      
      if (holder.foreigner === 'N') {
        holderIsValid = this.validateResident(holder);
      } else {
        holderIsValid = this.validateForeigner(holder);
      }

      if (!holderIsValid) { return step }

      let touristsValid =  true;

      for (let i = 0; i < insureds.length; i++) {
        const insured = insureds[i]

        touristsValid = insured.foreigner === 'N' ? this.validateResident(insured) : this.validateForeigner(insured)

        if(!touristsValid) { return step }
      }

      step = 7

      return step;
    },
    isCurrencyRatesAvailable() {
      const now = moment().unix();
      const today = moment().startOf('day').unix();


      return !(now >= today && now <= (today + 600));
    }
  },
  methods: {
    ...mapActions({
      getCityList: 'dictionary/getCityList',
      getCountryList: 'dictionary/getCountryList',
      getPersonInfo: 'getPersonInfo',
      getTravelerDictionary: 'dictionary/getTravelerDictionary'
    }),
    selectDate(){
      this.$store.commit('travelerForm/FILL_FORM', this.form)
    },
    selectPacket(){
      this.$store.commit('travelerForm/FILL_PACKET', this.packet)
    },
    async loading(callback) {
      const loader = this.$loading.show();
      await callback();
      loader.hide();
    },
    async setDefaultInfo(){
      if(this.formFinal){
        this.form = this.formFinal
      }
      if(this.packetFinal){
        this.packet = this.packetFinal
      }
      if(this.amountFinal){
        this.amount = this.amountFinal
      }
      const { authUser } = this;

      this.form.phone_number = `7${authUser.phone_number}`;

      if(!this.form.holder.individual_id_number) {
        this.form.holder.uuid = uuidv4();
        this.form.holder.individual_id_number = authUser.individual_id_number;

      }
    },
    resetAmount() {
      this.amount = 0;
    },
    resetPeriod() {
      this.form.period = null;
      this.form.duration = null;
      this.form.end_date = null;
    },
    resetSport() {
      this.isAthlete = false;
      this.form.sport = null;
    },
    changePacket(value) {
      this.resetAmount();
      this.clearSelectedAmount();
      this.resetPeriod();
      this.resetSport();
      this.form.end_date = null;

      let cover_type = this.packets.find((packet) => packet.id === value);
      this.form.cover_type = cover_type ? cover_type.code : null;

    },
    async changeHolder({item}) {
      this.form.holder = item
    },
    async getCalculation() {
      this.amountLoaded = false;

      try {
        const calculation = await travelerCalculation(this.form);
        this.amount = calculation.amount;
        this.$store.commit('travelerForm/FILL_AMOUNT', this.amount )
      } catch (e) {
        this.amount = 0;
      }finally {
        this.amountLoaded = true;
      }
    },
    async onSubmit() {
      const loader = this.$loading.show();
      if (!this.isCurrencyRatesAvailable) {
        this.$modal.show('dialog', {
          title: '',
          text: 'Уважаемый клиент, на текущий момент меняется курс валюты. Расчет и оформление мед страховки туриста будет доступно после 00:10.',
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }]
        });
        loader.hide();
        return;
      }
      try {
        await this.checkHolder();
        await this.checkTourists();
      } catch (e) {
        this.isModal = true
        await eventLogger('travel_fail')
        return loader.hide();
      }

      try {
        if (!this.clientsChecked) {
          return loader.hide();
        }

        eventLogger('select_payment_travel', {payment_sum: this.amount});

        const data = await travelerCalculation(this.form);

        this.amount = data.amount;

        loader.hide();

        await this.$router.push({
          name: "insurance.products.checkout",
          params: {id: data.id},
        });


      } catch (e) {
        this.isModal = true
        this.amount = 0;
      }
    },
    saveDraft() {
      window.axios.post('/policy-draft', this.form)
    }
  },
  watch: {
    insuranceType: {
      immediate: true,
      handler(value) {
        this.form.insurance_type = value

      }
    },
    isCovidProtect: function (isCovid){
      if(this.form.cover_type) {
        if (isCovid) {
          this.form.cover_type = switchPrograms[this.form.cover_type]
        } else {
          this.form.cover_type = switchCovidPrograms[this.form.cover_type]
        }
        this.packet = this.packets.find(val => val.code === this.form.cover_type).id
      }
    },
    "form.is_multiple": function (multipleTrip) {
      if (multipleTrip) {
        this.packet = this.packets.find(val => val.code === this.multiProgram).id
        this.form.cover_type = this.multiProgram;
        this.changePacket()
      } else {
        this.form.cover_type = null;
        this.packet = null
        this.changePacket()
      }

    },
    "form.cover_type": function (coverType) {
      this.form.franchise = null;

      if (coverType === this.educationProgram && this.isAthlete) {
        this.form.cover_type = this.educationSportProgram;
      } else {
        this.form.cover_type = coverType;
      }

    },
    "form.owner_is_holder": function(value) {
      if (!value && this.form.insureds.length === 0) {
        this.addTourist()
      }
    },
    "form.start_date": function (value) {
      if (this.travelIsMultiple || this.travelIsEducational) {
        this.form.end_date = this.addDays(value, this.form.period);
      }
      this.$store.commit('travelerForm/FILL_FORM', this.form)
    },
    "form.period": function (value) {
      if (this.travelIsMultiple || this.travelIsEducational) {
        this.form.end_date = this.addDays(this.form.start_date, value);
      }

      switch (String(value)) {
        case '180':
          this.form.duration = 45;
          break;
        case '90':
          this.form.duration = 30;
          break;
        case '30':
          this.form.duration = 10;
          break;
        default:
          this.form.duration = null;
      }
      this.$store.commit('travelerForm/FILL_FORM', this.form)
    },
    packet: function (value) {
      // this.resetAmount();
      // this.clearSelectedAmount();
      // this.resetPeriod();
      // this.resetSport();
      // this.form.end_date = null;

      let cover_type = this.packets.find((packet) => packet.id === value);
      this.form.cover_type = cover_type ? cover_type.code : null;
      this.$store.commit('travelerForm/FILL_FORM', this.form)
    },
    isAthlete: function (state) {
      if (this.travelIsEducational) {
        this.form.cover_type = state ? this.educationSportProgram  : this.educationProgram;
      }

    },
    alreadyInCountry: function(value) {
      if(value) { this.resetAmount() }
    },
    form: {
      deep: true,
      async handler() {
        this.debouncedSaveDraft()

        if (this.insuranceCurrency === "USD" && (Number(this.form.period) !== 30 || Number(this.form.duration) !== 10)) {
          this.form.franchise = null;
        }

        if(this.formStepValue >= 7){
          await this.debouncedGetCalculation()
        }
      }
    },
  }
}
</script>
