<template>
  <div>
    <input-field
        :label="carName"
        :is-block="true"
        rules="required|min:7"
        name="car_number"
        mask="VVVVVVVV"
        :value="license_plate"
        @input="validateCarNumber"
    />
    <p class="input-helper text-muted">{{ $t('enterValidLicensePlate') }}</p>
    <div class="addition-invalid" v-if="carError">
      <icon icon-name="read-info-icon" class="me-2"></icon>
      <span class="">{{ carError }}</span>
    </div>

    <div v-if="canBeSelectedCars.length" id="car-numbers-helper">
      <label>Выберите госномер</label>
      <div v-for="item in canBeSelectedCars">
        <div class="mb-3">
          <button class="numbers-button" @click="validateCarNumber(item)">{{ item }}</button>
        </div>
      </div>
    </div>

    <ValidationProvider rules="required">
      <input type="hidden" name="car_model" v-model="form.model"/>
      <input type="hidden" name="car_model"  v-model="form.model"/>
      <input type="hidden" name="car_territory" v-model="form.territory"/>
      <input type="hidden" name="car_type" v-model="form.type"/>
      <input type="hidden" name="car_year"  v-model="form.year"/>
    </ValidationProvider>
  </div>

</template>

<script>
import InputField from "./BuyPolicy/InputField";
import {getCarByLicensePlate} from "../api/info";

export default {
  components: {
    InputField
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    value: Object,
    canDelete: {
      type: Boolean,
      default: false
    },
    availableTypes: {
      type: Array,
      default: () => []
    },
    canBeSelectedCars: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      license_plate: this?.value?.license_plate,
      form: {
        license_plate: null,
        model: null,
        territory: null,
        title: null,
        type: null,
        year: null,
      },
      fullInfo: null,
      carError: null,
    }
  },
  computed: {
    carName() {
      const { fullInfo } = this

      if(!fullInfo) {
        return this.$i18n.t('carRegNumber');
      }

      return `${fullInfo.make} ${fullInfo.model} ${fullInfo.editionDate}`;
    }
  },
  mounted() {
    const { value } = this;
    if (value) {
      this.form = { ...value }
    }
  },
  methods: {
    async validateCarNumber(val) {
      if(!val) {
        return this.resetForm()
      }

      if (
          val.toLowerCase().match(/[\d]{3}[A-Z]{3}[\d]{2}/i) || // новые номера
          val.toLowerCase().match(/[\d]{2}[A-Z]{3}[\d]{2}/i) || // номера с прицепом
          val.toLowerCase().match(/[\d]{2}[A-Z]{2}[\d]{2}/i) || // номера с прицепом
          val.toLowerCase().match(/[A-Z]{1}[\d]{3}[a-zA-Z]{3}/i) || // старые номера
          val.toLowerCase().match(/[A-Z]{1}[\d]{6}/i) // старые номера
      ) {
        this.carError = '';

        const loader = this.$loading.show({});

        try{
          const data = await getCarByLicensePlate(val);

          // Проверка на типы
          if(this.availableTypes.length !== 0 && !this.availableTypes.includes(data.carType)) {
            throw { message: this.$i18n.t('cascoOnlyCars')}
          }

          if(this.form.license_plate !== data.regNumber){
            this.form.license_plate = data.regNumber
            this.form.model = data.model
            this.form.territory = data.territory
            this.form.title = data.title
            this.form.type = data.carType
            this.form.year = data.editionDate
            this.license_plate = this.form.license_plate
          }
          this.$emit('changed', { item: this.form, index: this.index })

          this.fullInfo = data;
        } catch (err) {
          const errorMessage = err?.response?.data?.message || err?.message

          this.resetForm()

          this.$emit('error', {
            title: `Ошибка при получение данных ТС, ${val}`,
            message: (err?.response?.data?.message || err?.message),
            code: err?.response?.data?.error_code || 0
          });

          this.carError = errorMessage ? errorMessage : this.$i18n.t('serverError')
        } finally {
          loader.hide()
        }
      }
    },
    removeItem() {
      this.$emit('removeItem', this.index);
    },
    resetForm() {
      this.carError = null
      this.form.license_plate = null
      this.form.model = null
      this.form.territory = null
      this.form.title = null
      this.form.type = null
      this.form.year = null
      this.fullInfo = null

      this.$emit('changed', { item: this.form, index: this.index })
    }
  }
}
</script>


<style scoped>
.numbers-button{
  padding: 0.5rem;
  background: #2AA65C;
  border-radius: 0.4rem;
  color: white;
}

.input-helper{
  font-size: 12px;
  line-height: 15px;
}
</style>