<template>
  <div>
    <div class="primary-block" :class="{ 'mb-0': addition }">
      <h2 class="input-list-title" v-if="title">{{ title }}</h2>
      <ul class="input-list">
        <li
          class="input-list-item"
          v-for="(option, index) in options"
          :key="index"
        >
         <div>
           <label class="primary-text label mb-0" :for="radioName + option.name">{{ getOptionLabel(option) }}
           </label>
           <span class="success-text" v-if="readMore" @click="$emit('more', option)">{{$t('details')}}</span>
         </div>
          <input
            :type="inputType"
            :name="radioName"
            :value="option.value || option.id"
            :id="radioName + option.name"
            @change="$emit('input', $event.target.value, option.name)"
            :checked="option.value == value || option.id == value"
          />
        </li>
      </ul>
    </div>
    <span class="addition-text" v-if="addition">{{ addition }}</span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "RadioInputList",
  props: {
    options: {
      type: Array,
    },
    value: null,
    title: {
      type: String,
    },
    addition: {
      type: String,
    },
    radioName: {
      type: String,
      default: "radio-name",
    },
    readMore: {
      type: Boolean,
    },
    rules: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: 'radio'
    }
  },
  computed: {
    ...mapGetters(['getAltTranslationFieldSuffix']),
  },
  methods: {
    getOptionLabel (option) {
      const { getAltTranslationFieldSuffix } = this;

      if(option.mergeProduct) {
        return option['mergeProduct']['name' + getAltTranslationFieldSuffix] || option['name'];
      }

      return option['name'] || option['title'];
    }

  }
};
</script>

<style scoped>
</style>
