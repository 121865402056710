<template>
	<div class="rates-wrap">
		<p class="title">{{ title }}</p>
		<div class="accordion-rates arrows">
			<template v-for="(item, index) in rates">
				<div class="accordion-item">
					<input v-model="selected" :value="index" @change="changeRate(index)" type="radio" name="accordion" :id="'cb'+ index" />
					<section class="box">
						<label class="box-title" :for="'cb'+ index">
							{{item.name}} <b>{{item.price}} ₸/мес</b>
						</label>
					
						<div class="box-content">
							<ul class="list-price">
								<template v-for="i in item.lists">
									<li>
										<span class="title-list">{{ i.name }}</span>
										<span class="price">{{ i.price }} ₸</span>
									</li>
								</template>
							</ul>
						</div>
					</section>
				</div>
			</template>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
		    title: {
		      type: String
		    },
		    rates: {
		    	type: Array
		    }
		},
		data(){
			return{
				selected: 0,
			}
		},
		methods: {
			changeRate(index){
				this.$emit('change_input', index);
			}
		}
	}
</script>
<style type="text/css" lang="scss">
	.rates-wrap{
		background: #FFFFFF;
		box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
		border-radius: 12px;
		padding: 22px 16px 0px;
		margin-bottom: 16px;
		.title{
			font-family: 'Manrope';
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			color: #071222;
		}
	}
	.accordion-rates {
		margin: auto;
		width: 100%;
	}
	.accordion-rates input[type=radio] {
		display: none;
	}
	.accordion-item{
		.box {
			position: relative;
			background: white;
			height: calc(100% / 4);
		    transition: all .20s ease-in-out;
		}
		.box-title {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 22px 0px 0px;
			padding-bottom: 22px;
			padding-right: 30px;
			cursor: pointer;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			font-family: 'Manrope';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
		}
		.box-content {
			width: 100%;
			display: none;
			height: calc(100% / 4);
		}
		.box-close {
			position: absolute;
		  	height: calc(100% / 4);
			width: 100%;
			top: 0;
			left: 0;
			cursor: pointer;
			display: none;
		}
		input:checked + .box {
			height: auto;
			padding: 0px 0 11px;
		   /* box-shadow: 0 0 10px rgba(0,0,0,.10),0 0 10px rgba(0,0,0,.10);*/
		}
		input:checked + .box .box-title {
		
		}
		input:checked + .box .box-content,
		input:checked + .box .box-close {
			display: inline-block;
		}
		.arrows section .box-title {
			padding-left: 44px;
			width: calc(100% - 64px);
		}
		
		input:checked + .box .box-title:before,
		input:not(:checked) + .box .box-title:before{
			content: '';
		    position: absolute;
		    right: 0;
		    top: 0;
		    width: 18px;
		    height: 18px;
		    border: 1px solid #9AA3B2;
		    border-radius: 100%;
		    background: #fff;
		}

		input:checked + .box .box-title:after,
		input:not(:checked) + .box .box-title:after{
			content: '';
		    width: 12px;
		    height: 12px;
		    background: #2AA65C;
		    position: absolute;
		    top: 4px;
		    right: 4px;
		    border-radius: 100%;
		    -webkit-transition: all 0.2s ease;
		    transition: all 0.2s ease;

		}
		input:checked + .box .box-title:before{
			border-color: #2AA65C;
		}
		input:not(:checked) + .box .box-title:after {
			opacity: 0;
    		-webkit-transform: scale(0);
    		transform: scale(0);
		}

		input:checked + .box .box-title:after {
			opacity: 1;
    		-webkit-transform: scale(1);
    		transform: scale(1);
		}
		.list-price{
			li{
				display: flex;
				justify-content: space-between;
				align-content: center;
				margin-bottom: 4px;
				span{
					font-weight: 500;
					font-size: 14px;
					line-height: 18px;
					color: #99A3B3;
				}
			}
		}


	}
	.accordion-item{
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}
	.accordion-item:last-child{
		border: none;
	}
</style>