<template>
  <div class="vue-country-list-wrap"
       :style="{'z-index': listZIndex != 0 ? listZIndex : '', 'max-height': maxHeight > 0 ? (maxHeight + 'px') : ''}">
    <div class="country-modal-search-box">
      <div class="modal-search-wrap">
        <input type="text" v-model="searchText" autocomplete="off" class="country-modal-search-input" placeholder="Введите название страны"/>
      </div>
    </div>
    <ul class="vue-country-list" @click="_countryItemClick">
      <li class="vue-country-item"
          v-for="(item, index) in countryList"
          :class="{selected: item.code === selected.code}"
          :key="item.id"
          :data-index="index"
          :data-iso="item.code">
        <country-flag :code="item.code"/>
        <span class="vue-country-name">{{ item.name }}</span>
      </li>
      <li class="vue-country-no-data" v-show="countryList.length === 0">
        <slot name="vueCountryNoData">{{noDataText}}</slot>
      </li>
    </ul>
  </div>
</template>

<script>
import {vueCountryTool} from "../plugins/vueCountryTool";
import CountryFlag from "./CountryFlag";

export default {
  name: "CountryList",
  components: {CountryFlag},
  props: {
    countriesData: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'phone',
    },
    iso2: {
      type: String,
      default: ''
    },
    listZIndex: { // 列表的层级
      type: Number,
      default: 0,
    },
    maxHeight: { // 列表最大高度
      type: Number,
      default: 0,
    },
    searchAble: {
      type: Boolean,
      default: true,
    },
    disableCountry: {
      type: [String, Array],
      default(){
        return [];
      }
    },
    onlyCountry: {
      type: [String, Array],
      default(){
        return [];
      }
    },
    noDataText: {
      type: String,
      default: 'Ничего не найдено'
    },
    // 是否使用中文显示国籍名称
    useChinese: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      currentResult: '',
      selected: {},
      searchText: null,
    };
  },
  computed: {
    countryList () {
      let searchText = this.searchText || '';
      let countries = this.countriesData;
      let disableCountry = typeof this.disableCountry === 'string' ? this.disableCountry.split(',') : this.disableCountry;
      let onlyCountry = typeof this.onlyCountry === 'string' ? this.onlyCountry.split(',') : this.onlyCountry;
      if(onlyCountry.length > 0){
        countries = countries.filter(country => {
          let index = this.getIndex(onlyCountry, (item) => {
            let dialCode = item + '';
            if(dialCode.charAt(0) === '+'){
              dialCode = dialCode.replace('+', '');
            }
            return country.name === item || country.nameCN === item || country.dialCode === dialCode || country.iso2 === item;
          });
          return index > -1;
        });
        // console.log('只显示指定国家', countries, onlyCountry)
      }

      if(disableCountry.length > 0){
        countries = countries.filter(country => {
          let index = this.getIndex(disableCountry, (item) => {
            let dialCode = item + '';
            if(dialCode.charAt(0) === '+'){
              dialCode = dialCode.replace('+', '');
            }
            return country.name === item || country.nameCN === item || country.dialCode === dialCode || country.iso2 === item;
          });
          return index === -1;
        });
      }
      if (!this.searchAble || searchText.length === 0) {
        return countries;
      }
      searchText = searchText.replace('+', '\\+');

      countries =  countries.filter(item => {
        let reg = new RegExp(searchText, 'gi');
        // console.log('reg',reg);
        let nameFlag = reg.test(item.name || item.nameCN);
        if(nameFlag){
          return true;
        }
        let dialCodeFlag = reg.test(item.dialCode);
        if(dialCodeFlag){
          return true;
        }
        let iso2Flag = reg.test(item.code);
        if(iso2Flag){
          return true;
        }
        // 有些国家的手机区号会有多个值
        let diaCodeInMultipleAreaCodeCountry = item.areaCodes && item.areaCodes.some(areaCode => searchText.search(areaCode) > -1);
        return diaCodeInMultipleAreaCodeCountry;
      });
      return countries;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(){
        // let cur = this.calcSelectedOption();
        let cur = vueCountryTool.calcSelectedOption(this.value, this.type, this.countryList);
        // 防止重复计算
        if(this.value == this.currentResult){
          return;
        }
        console.log('watch', cur, this.selected);
        console.log('watch', this.value, this.currentResult);
        if(cur !== this.selected){
          this.selected = cur;
          this.currentResult = this.value;
          // 设置显示的默认值
          this.$emit('selectedChange', cur);
        }
      }
    }
  },
  methods: {
    _countryItemClick (e) {
      e = e || window.event;
      e.stopPropagation ? e.stopPropagation() : e.cancelBubble = true;
      let target = e.target;
      let selected;
      if(this.justRead){
        return;
      }
      if (target.nodeName !== 'LI') {
        target = target.parentElement;
      }

      let countryList = this.countryList;
      let iso = target.getAttribute('data-iso');
      let index = -1;

      this.$emit('selectedChange', iso);

      for(let i = 0, len = countryList.length; i < len; i++){
        if(countryList[i].code === iso){
          index = i;
          selected = countryList[i];
          break;
        }
      }

      if(!selected){
        return;
      }

      if (this.isManualShow) {
        this.inputFocused = false;
        this.countryListShow = false;
        this.isManualShow = false;
      }
      this.selected = selected;
      let result = '';
      if(this.type.toLowerCase() === 'phone'){
        if(selected.dialCode == 1 && selected.areaCodes) {
          result = selected.areaCodes[0];
        }else {
          result = selected.dialCode || '';
        }
      }else{
        result = selected.code || '';
      }
      this.currentResult = result;
      this.$emit('input', result);
      // 执行回调
      this.$emit('onchange', selected, result);
    },
    getIndex(arr, fn) {
      if (!arr || arr.length == 0 || !fn || (typeof fn != "function")) {
        return -1;
      }
      if (arr.findIndex) {
        return arr.findIndex(fn);
      }
      let len = arr.length,
          i = 0,
          index = -1;
      for (; i < len; i++) {
        let item = arr[i];
        if (fn(item, index, arr) === true) {
          index = i;
          break;
        }
      }
      return index;
    },
  },
  mounted() {
    let cur = vueCountryTool.calcSelectedOption(this.value, this.type, this.countryList);
    if(this.type == 'phone' && (this.code + '').length == 0){
      console.error('当type=phone时最好传递iso2属性，否则当区号代码为212或358时会出现选择不正确问题！');
    }
    if(cur.code){
      // 执行回调。告诉父组件，以让父组件显示到界面
      this.$emit('onchange', cur, this.type.toLowerCase() === 'phone' ? (cur.dialCode || '') : (cur.code || ''));
    }
  }
}
</script>

<style>
.vue-country-list-wrap {
  display: flex;
  flex-direction: column;
}
.vue-country-item{
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}
.vue-country-item.selected{
  color: #fff;
  background-color: #2AA65C;
}
.vue-country-item:not(.selected):hover{
  color: #fff;
  background-color: #41b883;
}
.vue-country-item span{
  display: inline-block;
  vertical-align: middle;
  word-break: break-all;
}
.vue-country-item .iti-flag{
  margin-right: 8px;
}
.vue-country-no-data{
  padding: 80px 0;
  text-align: center;
  color: #999;
}
@media only screen and (max-width: 991px) {
  .vue-country-list-wrap{
    max-height: 75vh;
  }
}
.country-modal-search-box{
  top: 0;
  width: 100%;
  padding: 0 15px;
}
.modal-search-wrap{
  margin: 10px 0;
  width: 100%;
  border-collapse: separate;
}
.country-modal-search-input{
  display: table-cell;
  float: left;
  width: 100%;
  padding: 10px 15px;
  background: rgba(231, 236, 240, 0.7);
  border-radius: 12px;
  outline: none;
  margin: 0;
  font-size: 14px;
  appearance: none; /* 去除iPhone输入框阴影 */
}
.vue-country-intl-modal .vue-country-list-wrap{
  position: static;
  height: 100%;
  max-height: none;
  border-bottom: none;
  border-radius: 0;
}

@media (min-width: 992px) {
  .country-modal-content{
    width: 500px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  .country-modal-search-box{
    padding: 0;
  }
}

@media (max-width: 991px) {
  .vue-country-intl-modal .vue-country-list-wrap{
    padding: 0 15px;
    border-left: none;
    border-right: none;
  }
  .vue-country-intl-modal .vue-country-list-wrap .vue-country-item{
    padding-left: 0;
  }
}
</style>