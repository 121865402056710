import { render, staticRenderFns } from "./RemoveButton.vue?vue&type=template&id=982d58c4&scoped=true"
import script from "./RemoveButton.vue?vue&type=script&lang=js"
export * from "./RemoveButton.vue?vue&type=script&lang=js"
import style0 from "./RemoveButton.vue?vue&type=style&index=0&id=982d58c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "982d58c4",
  null
  
)

export default component.exports