<template>
	<ul class="list-price">
		<template v-for="i in main_lists">
			<li>
				<span class="title-list">{{ i.name }}</span>
				<span class="price">{{ i.price }} ₸</span>
			</li>
		</template>
	</ul>
</template>
<script>
	export default {
		props: {
		    lists: {
		    	type: Array
		    },
		    category:{
		    	type: String
		    },
		    tariff:{
		    	type: String
		    }
		},
		data(){
			return{
				main_lists: [],
				main_index: 0,
				casco: [
					[{ name: 'Выплата Вам', price: '200 000' }],
					[{ name: 'Выплата Вам', price: '300 000' }],
					[{ name: 'Выплата Вам', price: '400 000' }]
				],
				flat: [
					[
			            { name: 'Выплата Вам ', price: '500 000' }, 
			            { name: 'Выплата соседям ', price: '500 000' }, 
			            { name: 'Возместим итого  ', price: '1 000 000' }, 
			        ],
			        [
			            { name: 'Выплата Вам', price: '1 000 000' },
			            { name: 'Выплата соседям', price: '1 000 000' },
			            { name: 'Возместим итого', price: '2 000 000' },
			        ],
			        [
			            { name: 'Выплата Вам', price: '1 500 000'},
			            { name: 'Выплата соседям', price: '1 500 000'},
			            { name: 'Возместим итого', price: '3 000 000'},
			        ]   
				],
				home: [
					[{ name: 'Выплата Вам', price: '1 000 000' }],
					[{ name: 'Выплата Вам', price: '2 000 000' }],
					[{ name: 'Выплата Вам', price: '3 000 000' }]
				]
			}
		},
		mounted(){
			if(this.tariff == "LIGHT"){
				this.main_index = 0;
			}else if(this.tariff == "STANDARD"){
				this.main_index = 1;
			}else{
				this.main_index = 2;
			}

			if(this.category.indexOf('КАСКО') > -1){
				this.main_lists = this.casco[this.main_index];
			}else if(this.category.indexOf('вартир') > -1){
				this.main_lists = this.flat[this.main_index];
			}else{
				this.main_lists = this.home[this.main_index];
			}
			
		},
	}
</script>