<template>
  <div>
    <div class="primary-block">
      <ul class="input-list">
        <li class="input-list-item">
          <label class="primary-text label" for="owner_is_holder_checkbox">{{ label }}</label>
          <input
              type="checkbox"
              id="owner_is_holder_checkbox"
              v-model="modelValue"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", !!value);
      }
    }
  },
};
</script>