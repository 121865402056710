<template>
  <modal-layout @close="$emit('close')">
    <div class="p-3 text-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="81" height="88" viewBox="0 0 81 88" fill="none">
        <g clip-path="url(#clip0_1_323)">
          <path d="M40.5 79.3504C60.0905 79.3504 75.9717 63.5235 75.9717 44C75.9717 24.4765 60.0905 8.64957 40.5 8.64957C20.9095 8.64957 5.02829 24.4765 5.02829 44C5.02829 63.5235 20.9095 79.3504 40.5 79.3504Z" fill="#F1F2F6"/>
          <path d="M69.4526 12.3672C72.874 12.3672 75.6476 9.59868 75.6476 6.18358C75.6476 2.76848 72.874 0 69.4526 0C66.0312 0 63.2577 2.76848 63.2577 6.18358C63.2577 9.59868 66.0312 12.3672 69.4526 12.3672Z" fill="#E0E6EF"/>
          <path d="M13.3263 87.8068C14.3527 87.8068 15.1848 86.9763 15.1848 85.9518C15.1848 84.9272 14.3527 84.0967 13.3263 84.0967C12.2999 84.0967 11.4678 84.9272 11.4678 85.9518C11.4678 86.9763 12.2999 87.8068 13.3263 87.8068Z" fill="#E0E6EF"/>
          <path d="M30.6506 87.8069C33.2538 87.8069 35.3641 85.6965 35.3641 83.0933C35.3641 80.4901 33.2538 78.3798 30.6506 78.3798C28.0474 78.3798 25.937 80.4901 25.937 83.0933C25.937 85.6965 28.0474 87.8069 30.6506 87.8069Z" fill="#E0E6EF"/>
          <mask id="mask0_1_323" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="-6" width="72" height="86">
            <path d="M40.3633 79.7264C59.999 79.7264 75.917 63.8085 75.917 44.1728C75.917 35.3881 76.8869 5.79991 71.6074 -0.404065C65.086 -8.06748 51.2144 -3.90554 40.3633 -3.90554C30.6364 -3.90554 16.2117 -3.36633 9.79247 2.96278C3.25309 9.41032 4.80957 34.2639 4.80957 44.1728C4.80957 63.8085 20.7275 79.7264 40.3633 79.7264Z" fill="#11D392"/>
          </mask>
          <g mask="url(#mask0_1_323)">
            <path d="M62.5147 2.16254H20.5379C15.5152 2.16254 11.4434 6.2021 11.4434 11.1852V114.381C11.4434 119.364 15.5152 123.404 20.5379 123.404H62.5147C67.5375 123.404 71.6093 119.364 71.6093 114.381V11.1852C71.6093 6.2021 67.5375 2.16254 62.5147 2.16254Z" fill="#E0E0E0"/>
            <path d="M62.5281 2.19684H20.5246C15.5533 2.19684 11.5233 6.19499 11.5233 11.127V114.389C11.5233 119.321 15.5533 123.319 20.5246 123.319H62.5281C67.4993 123.319 71.5294 119.321 71.5294 114.389V11.127C71.5294 6.19499 67.4993 2.19684 62.5281 2.19684Z" fill="#E1E1E1"/>
            <path d="M62.5413 2.23032H20.5112C15.5914 2.23032 11.6031 6.18706 11.6031 11.0679V114.397C11.6031 119.278 15.5914 123.234 20.5112 123.234H62.5413C67.4611 123.234 71.4494 119.278 71.4494 114.397V11.0679C71.4494 6.18706 67.4611 2.23032 62.5413 2.23032Z" fill="#E2E2E2"/>
            <path d="M62.5553 2.26431H20.4978C15.6295 2.26431 11.683 6.17964 11.683 11.0095V114.404C11.683 119.234 15.6295 123.149 20.4978 123.149H62.5553C67.4236 123.149 71.3702 119.234 71.3702 114.404V11.0095C71.3702 6.17964 67.4236 2.26431 62.5553 2.26431Z" fill="#E3E3E3"/>
            <path d="M62.5687 2.29852H20.483C15.6666 2.29852 11.7622 6.1721 11.7622 10.9504V114.414C11.7622 119.192 15.6666 123.066 20.483 123.066H62.5687C67.3851 123.066 71.2895 119.192 71.2895 114.414V10.9504C71.2895 6.1721 67.3851 2.29852 62.5687 2.29852Z" fill="#E4E4E4"/>
            <path d="M62.582 2.33264H20.4696C15.7047 2.33264 11.842 6.1648 11.842 10.892V114.421C11.842 119.149 15.7047 122.981 20.4696 122.981H62.582C67.3468 122.981 71.2095 119.149 71.2095 114.421V10.892C71.2095 6.1648 67.3468 2.33264 62.582 2.33264Z" fill="#E5E5E5"/>
            <path d="M62.5952 2.36603H20.4562C15.7428 2.36603 11.9218 6.15678 11.9218 10.8329V114.429C11.9218 119.105 15.7428 122.896 20.4562 122.896H62.5952C67.3086 122.896 71.1295 119.105 71.1295 114.429V10.8329C71.1295 6.15678 67.3086 2.36603 62.5952 2.36603Z" fill="#E6E6E6"/>
            <path d="M62.6092 2.40024H20.4428C15.7809 2.40024 12.0017 6.14957 12.0017 10.7746V114.438C12.0017 119.063 15.7809 122.812 20.4428 122.812H62.6092C67.2711 122.812 71.0503 119.063 71.0503 114.438V10.7746C71.0503 6.14957 67.2711 2.40024 62.6092 2.40024Z" fill="#E7E7E7"/>
            <path d="M62.6226 2.43442H20.4295C15.8191 2.43442 12.0816 6.14234 12.0816 10.7163V114.445C12.0816 119.019 15.8191 122.727 20.4295 122.727H62.6226C67.233 122.727 70.9705 119.019 70.9705 114.445V10.7163C70.9705 6.14234 67.233 2.43442 62.6226 2.43442Z" fill="#E8E8E8"/>
            <path d="M62.6357 2.46863H20.416C15.8571 2.46863 12.1614 6.13514 12.1614 10.658V114.454C12.1614 118.977 15.8571 122.643 20.416 122.643H62.6357C67.1946 122.643 70.8904 118.977 70.8904 114.454V10.658C70.8904 6.13514 67.1946 2.46863 62.6357 2.46863Z" fill="#E9E9E9"/>
            <path d="M62.6495 2.50217H20.4032C15.8957 2.50217 12.2417 6.12727 12.2417 10.5991V114.461C12.2417 118.933 15.8957 122.558 20.4032 122.558H62.6495C67.1569 122.558 70.8109 118.933 70.8109 114.461V10.5991C70.8109 6.12727 67.1569 2.50217 62.6495 2.50217Z" fill="#EAEAEA"/>
            <path d="M62.6634 2.53629H20.3896C15.9337 2.53629 12.3214 6.11997 12.3214 10.5407V114.47C12.3214 118.89 15.9337 122.474 20.3896 122.474H62.6634C67.1193 122.474 70.7316 118.89 70.7316 114.47V10.5407C70.7316 6.11997 67.1193 2.53629 62.6634 2.53629Z" fill="#EBEBEB"/>
            <path d="M62.676 2.57047H20.3756C15.9711 2.57047 12.4006 6.11274 12.4006 10.4824V114.477C12.4006 118.847 15.9711 122.389 20.3756 122.389H62.676C67.0804 122.389 70.6509 118.847 70.6509 114.477V10.4824C70.6509 6.11274 67.0804 2.57047 62.676 2.57047Z" fill="#ECECEC"/>
            <path d="M62.6896 2.6048H20.3625C16.0096 2.6048 12.4808 6.10566 12.4808 10.4242V114.486C12.4808 118.804 16.0096 122.305 20.3625 122.305H62.6896C67.0425 122.305 70.5713 118.804 70.5713 114.486V10.4242C70.5713 6.10566 67.0425 2.6048 62.6896 2.6048Z" fill="#EDEDED"/>
            <path d="M62.7037 2.63821H20.3484C16.0474 2.63821 12.5607 6.09732 12.5607 10.3643V114.495C12.5607 118.762 16.0474 122.221 20.3484 122.221H62.7037C67.0047 122.221 70.4914 118.762 70.4914 114.495V10.3643C70.4914 6.09732 67.0047 2.63821 62.7037 2.63821Z" fill="#EEEEEE"/>
            <path d="M62.7178 2.67239H20.3351C16.0856 2.67239 12.6407 6.09009 12.6407 10.306V114.502C12.6407 118.718 16.0856 122.136 20.3351 122.136H62.7178C66.9673 122.136 70.4123 118.718 70.4123 114.502V10.306C70.4123 6.09009 66.9673 2.67239 62.7178 2.67239Z" fill="#EFEFEF"/>
            <path d="M62.7311 2.70663H20.3217C16.1237 2.70663 12.7205 6.08292 12.7205 10.2478V114.511C12.7205 118.676 16.1237 122.052 20.3217 122.052H62.7311C66.9291 122.052 70.3323 118.676 70.3323 114.511V10.2478C70.3323 6.08292 66.9291 2.70663 62.7311 2.70663Z" fill="#F0F0F0"/>
            <path d="M62.7444 2.74084H20.3085C16.1619 2.74084 12.8005 6.07571 12.8005 10.1895V114.519C12.8005 118.632 16.1619 121.967 20.3085 121.967H62.7444C66.891 121.967 70.2524 118.632 70.2524 114.519V10.1895C70.2524 6.07571 66.891 2.74084 62.7444 2.74084Z" fill="#F0F0F0"/>
            <path d="M62.7577 2.77423H20.2951C16.2 2.77423 12.8803 6.06769 12.8803 10.1304V114.526C12.8803 118.589 16.2 121.883 20.2951 121.883H62.7577C66.8527 121.883 70.1724 118.589 70.1724 114.526V10.1304C70.1724 6.06769 66.8527 2.77423 62.7577 2.77423Z" fill="#F1F1F1"/>
            <path d="M62.772 2.80844H20.282C16.2384 2.80844 12.9604 6.06049 12.9604 10.0721V114.535C12.9604 118.547 16.2384 121.799 20.282 121.799H62.772C66.8155 121.799 70.0935 118.547 70.0935 114.535V10.0721C70.0935 6.06049 66.8155 2.80844 62.772 2.80844Z" fill="#F2F2F2"/>
            <path d="M62.7843 2.84262H20.2677C16.2756 2.84262 13.0394 6.05326 13.0394 10.0138V114.543C13.0394 118.503 16.2756 121.714 20.2677 121.714H62.7843C66.7764 121.714 70.0126 118.503 70.0126 114.543V10.0138C70.0126 6.05326 66.7764 2.84262 62.7843 2.84262Z" fill="#F3F3F3"/>
            <path d="M62.7977 2.8761H20.2545C16.3139 2.8761 13.1194 6.04532 13.1194 9.95476V114.551C13.1194 118.46 16.3139 121.629 20.2545 121.629H62.7977C66.7383 121.629 69.9328 118.46 69.9328 114.551V9.95476C69.9328 6.04532 66.7383 2.8761 62.7977 2.8761Z" fill="#F4F4F4"/>
            <path d="M62.8118 2.91028H20.2411C16.352 2.91028 13.1993 6.03809 13.1993 9.89644V114.558C13.1993 118.417 16.352 121.544 20.2411 121.544H62.8118C66.7009 121.544 69.8537 118.417 69.8537 114.558V9.89644C69.8537 6.03809 66.7009 2.91028 62.8118 2.91028Z" fill="#F5F5F5"/>
            <path d="M62.8251 2.94449H20.2277C16.3901 2.94449 13.2791 6.03089 13.2791 9.83815V114.567C13.2791 118.374 16.3901 121.461 20.2277 121.461H62.8251C66.6627 121.461 69.7737 118.374 69.7737 114.567V9.83815C69.7737 6.03089 66.6627 2.94449 62.8251 2.94449Z" fill="#F6F6F6"/>
            <path d="M62.8391 2.97867H20.2136C16.4279 2.97867 13.359 6.02331 13.359 9.77906V114.575C13.359 118.331 16.4279 121.376 20.2136 121.376H62.8391C66.6248 121.376 69.6937 118.331 69.6937 114.575V9.77906C69.6937 6.02331 66.6248 2.97867 62.8391 2.97867Z" fill="#F7F7F7"/>
            <path d="M62.8525 3.01215H20.2003C16.4661 3.01215 13.4389 6.01537 13.4389 9.72004V114.583C13.4389 118.288 16.4661 121.291 20.2003 121.291H62.8525C66.5867 121.291 69.6138 118.288 69.6138 114.583V9.72004C69.6138 6.01537 66.5867 3.01215 62.8525 3.01215Z" fill="#F8F8F8"/>
            <path d="M62.8667 3.04633H20.1871C16.5044 3.04633 13.519 6.00814 13.519 9.66173V114.592C13.519 118.245 16.5044 121.207 20.1871 121.207H62.8667C66.5494 121.207 69.5348 118.245 69.5348 114.592V9.66173C69.5348 6.00814 66.5494 3.04633 62.8667 3.04633Z" fill="#F9F9F9"/>
            <path d="M62.8792 3.08054H20.173C16.5418 3.08054 13.5981 6.00094 13.5981 9.60344V114.6C13.5981 118.202 16.5418 121.122 20.173 121.122H62.8792C66.5104 121.122 69.4541 118.202 69.4541 114.6V9.60344C69.4541 6.00094 66.5104 3.08054 62.8792 3.08054Z" fill="#FAFAFA"/>
            <path d="M62.8925 3.11465H20.1596C16.5799 3.11465 13.6779 5.99365 13.6779 9.54506V114.608C13.6779 118.159 16.5799 121.038 20.1596 121.038H62.8925C66.4722 121.038 69.3741 118.159 69.3741 114.608V9.54506C69.3741 5.99365 66.4722 3.11465 62.8925 3.11465Z" fill="#FBFBFB"/>
            <path d="M62.9057 3.14813H20.1462C16.618 3.14813 13.7578 5.98571 13.7578 9.48605V114.615C13.7578 118.115 16.618 120.953 20.1462 120.953H62.9057C66.434 120.953 69.2941 118.115 69.2941 114.615V9.48605C69.2941 5.98571 66.434 3.14813 62.9057 3.14813Z" fill="#FCFCFC"/>
            <path d="M62.9199 3.18237H20.1329C16.6562 3.18237 13.8377 5.97854 13.8377 9.42779V114.624C13.8377 118.073 16.6562 120.869 20.1329 120.869H62.9199C66.3966 120.869 69.2151 118.073 69.2151 114.624V9.42779C69.2151 5.97854 66.3966 3.18237 62.9199 3.18237Z" fill="#FDFDFD"/>
            <path d="M62.9332 3.21652H20.1196C16.6944 3.21652 13.9176 5.97128 13.9176 9.36944V114.631C13.9176 118.029 16.6944 120.784 20.1196 120.784H62.9332C66.3584 120.784 69.1351 118.029 69.1351 114.631V9.36944C69.1351 5.97128 66.3584 3.21652 62.9332 3.21652Z" fill="#FEFEFE"/>
            <path d="M62.9464 3.2507H20.1061C16.7324 3.2507 13.9974 5.96405 13.9974 9.31113V114.64C13.9974 117.987 16.7324 120.7 20.1061 120.7H62.9464C66.3201 120.7 69.0551 117.987 69.0551 114.64V9.31113C69.0551 5.96405 66.3201 3.2507 62.9464 3.2507Z" fill="white"/>
            <path d="M63.4526 4.29379H19.5994C16.146 4.29379 13.3465 7.07117 13.3465 10.4972V114.455C13.3465 117.881 16.146 120.658 19.5994 120.658H63.4526C66.906 120.658 69.7055 117.881 69.7055 114.455V10.4972C69.7055 7.07117 66.906 4.29379 63.4526 4.29379Z" fill="#FBFBFB"/>
            <path d="M41.6321 36.4786L41.6321 41.3675" stroke="#F5C400" stroke-width="1.88034" stroke-linecap="round"/>
            <path d="M41.4915 45.5043L41.4915 45.5043" stroke="#F5C400" stroke-width="1.88034" stroke-linecap="round"/>
            <path d="M38.2346 28.5812C39.6821 26.0741 43.3008 26.0741 44.7483 28.5812L54.8445 46.0684C56.292 48.5755 54.4827 51.7094 51.5877 51.7094H31.3952C28.5003 51.7094 26.6909 48.5755 28.1384 46.0684L38.2346 28.5812Z" stroke="#F5C400" stroke-width="1.88034" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <g filter="url(#filter0_d_1_323)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4409 18.092C13.095 18.092 15.2466 15.9639 15.2466 13.3387C15.2466 10.7135 13.095 8.58539 10.4409 8.58539C7.78675 8.58539 5.63515 10.7135 5.63515 13.3387C5.63515 15.9639 7.78675 18.092 10.4409 18.092Z" fill="url(#paint0_linear_1_323)"/>
          </g>
          <path d="M10.2778 15.5266V15.4188" stroke="white" stroke-width="0.808039" stroke-linecap="round"/>
          <path d="M10.2778 11.2821V13.9146" stroke="white" stroke-width="0.808039" stroke-linecap="round"/>
        </g>
        <defs>
          <filter id="filter0_d_1_323" x="2.94168" y="6.43062" width="14.9984" height="14.8935" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="0.538693"/>
            <feGaussianBlur stdDeviation="1.34673"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_323"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_323" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear_1_323" x1="20.0524" y1="13.3387" x2="10.5464" y2="3.72777" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF6C00"/>
            <stop offset="1" stop-color="#FFDD00"/>
          </linearGradient>
          <clipPath id="clip0_1_323">
            <rect width="80" height="88" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
      <h2 class="fw-bold">
        Уважаемый клиент!
      </h2>
      <p class="gray"> По указанным Вами данными
                имеется действующий полис</p>
    </div>
    <button class="primary-btn center-page-btn" @click="$emit('close')">Продолжить</button>
  </modal-layout>
<!--  <div>-->
<!--    <div class="p-3">-->


<!--      <p> По указанным Вами данными-->
<!--        имеется действующий полис</p>-->
<!--    </div>-->
<!--    <button class="bottom-page-btn primary-btn" @click="$emit('close')">Продолжить</button>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "TermsModal"
}
</script>

<style scoped>
.gray{
  color: #2A353D
}
</style>
