<template>
  <div>
    <div class="primary-block" :class="{'mb-0': addition}">
      <h2 class="input-list-title" v-if="title">{{ title }}</h2>
      <ul class="input-list">
        <li class="input-list-item" v-for="(option, index) in options" :key="index">
          <label class="primary-text label" :for="radioName + option.name">{{ option.name }}
            <span class="success-text" v-if="readMore">{{$t('details')}}</span>
          </label>
          <input class="" type="checkbox" v-model="selectedOption" :name="radioName" :value="option.value" :id="radioName + option.name" :checked="selectedOption">
        </li>
      </ul>
    </div>
    <span class="addition-text" v-if="addition">{{ addition }}</span>
  </div>
</template>

<script>
export default {
  name: "CheckboxList",
  props: {
    options: {
      type: Array
    },
    value: {
      type: Boolean,
      default: null
    },
    title: {
      type: String
    },
    addition: {
      type: String
    },
    radioName: {
      type: String,
      default: 'radio-name'
    },
    readMore: {
      type: Boolean
    },
    rules: {
      type: String,
      default: ''
    },
    for: {
      type: String
    }
  },
  data() {
    return {
      selectedOption: false,
    }
  },
  mounted() {
    if (this.value) {
      this.selectedOption = this.value;
    }
  },
  watch: {
    selectedOption(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
