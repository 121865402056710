<template>
  <modal-layout @close="$emit('close')" :title="$t('Подтверждение документов')">
    <div class="modal-content">
      <div class="row justify-content-center">
        <ClientDocument
            v-for="(client, index) in clients"
            :key="`client_${index}_${client.iin}`"
            :client-index="index"
            :client-info="client"
            @changed="clientDocChanged"
        />
      </div>
    </div>

    <button class="bottom-page-btn primary-btn" @click="setNewClients">{{$t('Продолжить')}}</button>
  </modal-layout>
</template>

<script>
import ClientDocument from "../BuyPolicy/ClientDocument";

export default {
  name: "ClientCheckModal",
  components: {ClientDocument},
  props: ['getterName', 'handler'],
  data() {
    return {
      errorMessage: null
    }
  },
  computed: {
    clients() {
      return this.$store.getters[this.getterName]
    }
  },
  methods: {
    clientDocChanged(form) {
      let docIndex = this.clients[form.index]['clientDocuments'].findIndex(doc => {
        return doc.docType === form.doc_type
      })

      if(docIndex === -1) {
        this.clients[form.index]['clientDocuments'].splice(0, 1, {
          doc_type: form.doc_type,
          doc_number: form.doc_number,
          doc_issue_date: form.doc_issue_date
        })

        return;
      }
      this.clients[form.index]['clientDocuments'][docIndex]['docNumber'] = form.doc_number;
      this.clients[form.index]['clientDocuments'][docIndex]['docIssueDate'] = form.doc_issue_date;
    },
    async setNewClients() {
      this.errorMessage = null;

      const loader = this.$loading.show();

      try{
        await this.handler();
        this.$emit('close');
      } catch (e) {
        this.errorMessage = e?.message
      }


      loader.hide();
    }
  }
}
</script>

<style scoped>

</style>
