<template>
  <div class="primary-block d-flex align-items-center">
    <div class="icon-box me-3">
      <icon icon-name="plus-icon"></icon>
    </div>
    <span class="success-text">{{$t('addInsured')}}</span>
  </div>
</template>

<script>
export default {
  name: "AddPersonButton"
}
</script>

<style scoped>

</style>
