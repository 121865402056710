<template>
  <div class="primary-block">
    <h3 class="input-list-title" v-if="title">{{ title }}</h3>

    <ul class="input-list">
      <li class="input-list-item" v-for="(car, index) in cars" :key="index">
        <div>
          <span class="primary-text">{{ car.govNumber }}</span>
          <span class="secondary-text">{{ car.mark }} {{ convertToStar(car.model) }} {{ `${car.prodYear} г.в` }}</span>
        </div>
        <div @click="$emit('delete', index)">
          <icon icon-name="close-button-icon" />
        </div>
      </li>
      <input-field
        v-if="addNewCarInput"
        :label="$t('addCarNumber')"
        :is-block="true"
        :value="carNumber"
        name="carNumber"
        mask="VVVVVVVV"
        @input="addCar"
        :loading="loading"
      />
      <p class="input-helper text-muted" v-if="addNewCarInput">{{ $t('enterValidLicensePlate') }}</p>
      <h5 class="danger-text">{{ localError }}</h5>
      <div
        @click="addNewCarField()"
        :class="['input-add-id', { 'input-add-disabled': addBtnDisabled || loading }]"
      >
        <div class="icon-box">
          <icon icon-name="spinner" v-if="loading"/>
          <icon icon-name="plus-icon" v-else></icon>
        </div>
        <span class="text">{{ buttonTitle }}</span>
      </div>
    </ul>
  </div>
</template>

<script>
import InputField from "../BuyPolicy/InputField";

export default {
  name: "AddCarInfo",
  components: { InputField },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    cars: {
      type: Array,
    },
    error: {
      type: String,
    },
    title: {
      type: String,
    },
    buttonTitle: {
      type: String,
    },
    addBtnDisabled: {
      type: Boolean,
    },
    addNewCarInput: {
      type: Boolean,
    },
    carNumber: {
      type: String,
    },
  },
  data(){
    return {
      localError: null
    }
  },
  watch: {
    error(newError) {
      this.localError = newError
    }
  },
  methods: {
    addCar(val) {
      if(val.length === 0) {
        this.localError = null
      }

      if (
        val.toLowerCase().match(/[\d]{3}[A-Z]{3}[\d]{2}/i) || // новые номера
        val.toLowerCase().match(/[\d]{2}[A-Z]{3}[\d]{2}/i) || // номера с прицепом
        val.toLowerCase().match(/[\d]{2}[A-Z]{2}[\d]{2}/i) || // номера с прицепом
        val.toLowerCase().match(/[A-Z]{1}[\d]{3}[a-zA-Z]{3}/i) ||// старые номера
        val.toLowerCase().match(/[A-Z]{1}[\d]{6}/i) // желтые номера
      ) {
        this.localError = null;

        this.$emit("input", val);
      }
    },
    addNewCarField() {
      const {addBtnDisabled} = this;

      if(addBtnDisabled) {
        return;
      }

      this.$emit("addNewField");
    },

    convertToStar(name){
      var modelName = ''
      if(name){
        name.split('').forEach(() => {
            modelName += '*';
        })
      }

      return modelName
    }
  },

};
</script>

<style scoped>
.input-helper {
  padding: 0 5px;
  font-size: 12px;
  line-height: 15px;
}
</style>
