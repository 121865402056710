<template>
  <modal-layout @close="$emit('close')" :title="$t('terms')">
    <div class="p-3">
      <h2 v-if="showName" v-html="name" class="bonus-modal__title injected-html">
      </h2>
      <div v-if="showDescription" v-html="description" class="bonus-modal__content text-section injected-html" style="text-align: justify">
      </div>
    </div>

    <div id="map-on-bonus"></div>
    <button class="bottom-page-btn primary-btn" @click="$emit('close')">{{$t('understand')}}</button>
  </modal-layout>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BonusModal",
  data() {
    return {
      info: null
    }
  },

  computed: {
    ...mapGetters(['getAltTranslationFieldSuffix']),

    showName() {
      const {info} = this;

      return !!(info?.title);
    },

    showDescription() {
      const {info} = this;

      return !!(info?.content);
    },

    name() {
      const { info } = this;
      return info.title
    },

    description() {
      const { info } = this;
      return info.content
    },

  },

  created() {
    this.info = this.$attrs.info;
  }
}
</script>

<style lang="scss">
.bonus-modal__title {
  font-weight: 800;
  font-size: 34px;
}
.bonus-modal__content {
  a {
    color: #2AA65C !important;
    font-size: initial !important;
  }
  h6 {
    font-weight: bold !important;
    font-size: 16px !important;
    margin: 12px 0 !important;
  }
}
</style>
