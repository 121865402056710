<template>
  <ValidationObserver ref="personForm">
    <div class="position-relative">
      <v-dialog/>
      <div class="close-btn" v-if="canDelete" @click="removeItem">
        <icon icon-name="close-modal-icon"></icon>
      </div>

      <input-field
          :label="personName"
          :is-block="true"
          name="iin"
          field-type="text"
          rules="required|min:12"
          :value="iin"
          @input="validateIin"
          mask="############"
          class-list="person-iin"
      />

      <div class="addition-invalid" v-if="clientError">
        <icon icon-name="read-info-icon" class="me-2"></icon>
        <span class="">{{ clientError }}</span>
      </div>

      <input-field
          v-if="addNewClient"
          :label="$t('lastName')"
          :is-block="true"
          v-model="form.last_name"
          name="last_name"
      />

      <input-field
          v-if="addNewClient"
          :label="$t('firstName')"
          :is-block="true"
          name="first_name"
          v-model="form.first_name"
      />

      <input-field
          v-if="addNewClient"
          :label="$t('surName')"
          :is-block="true"
          v-model="form.s_name"
          name="s_name"
      />

      <select-date
          v-if="addNewClient"
          v-model="form.birth_date"
          :is-block="true"
          rules="required"
          name="birth_date"
          :title="$t('dateOfBirth')"/>

      <search-select
          v-show="showGenderField"
          :options="genders"
          :placeholder="$t('sex')"
          :reduce="gender => gender.value"
          :is-block="true"
          label="name"
          name="gender"
          v-model="form.gender"
      />
      <search-select
          v-show="showDocBlock"
          :options="documents"
          :is-block="true"
          :reduce="item => item.value"
          label="name"
          name="selected_doc"
          rules="required"
          :placeholder="$t('docType')"
          v-model="form.doc_type"
      />

      <input-field-list :title="$t('docIdConfirm')" v-show="showDocBlock">
        <span style="color: red" v-if="value.checkMessage">
          {{ value.checkMessage }}
        </span>
        <input-field
            v-show="showDocBlock"
            :label="$t('docNumber')"
            field-type="tel"
            rules="required"
            name="doc_number"
            v-model="form.doc_number"/>

        <select-date
            v-show="showDocBlock"
            v-model="form.doc_issue_date"
            rules="required"
            name="doc_issue_date"
            :title="$t('docGiveDate')"
        />
      </input-field-list>
    </div>
  </ValidationObserver>
</template>

<script>

import InputField from "./BuyPolicy/InputField";
import SearchSelect from "./BuyPolicy/SearchSelect";
import InputFieldList from "./BuyPolicy/InputFieldList";
import SelectDate from "./BuyPolicy/SelectDate";
import {getPeopleByIin} from "../api/info";
import moment from "moment";
import Vue from "vue";
import eventLogger from "../services/eventLogger";

export default {
  components: {
    InputField,
    SearchSelect,
    InputFieldList,
    SelectDate
  },
  props: {
    ageRestrictionMin:null,
    ageRestrictionMax:null,
    defaultDocType: {
      type: String,
      default: 'IC'
    },
    defaultDocRequired: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    value: Object,
  },
  data(){
    return {
      iin: this?.value?.individual_id_number,
      form: {
        individual_id_number: null,
        client_id: null,
        first_name: null,
        last_name: null,
        s_name: null,
        birth_date: null,
        gender: null,
        doc_type: null,
        doc_number: null,
        doc_issue_date: null,
        address_region: null,
        address_state: null,
        address_city: null,
        address_street_full: null,
        address_street_no: null,
        address_flat_no: null,
        new_client: false,
        checked: true,
        checkMessage: null
      },
      documents: [
        {name: this.$i18n.t('kazID'), value: 'IC'},
        {name: this.$i18n.t('passport'), value: 'PS'},
        {name: this.$i18n.t('birthCertificate'), value: 'BC'},
        {name: this.$i18n.t('nonResidentPermit'), value: 'RPF'},
        {name: this.$i18n.t('driverLicense'), value: 'DL'},
      ],
      genders: [
        { name: this.$i18n.t('male'), value: 1 },
        { name: this.$i18n.t('female'),value: 2 },
      ],
      fullInfo: null,
      clientError: null,
      showCitySelect: false,
      showDocInfo: false
    }
  },
  computed: {
    addNewClient() {
      return this.form.new_client;
    },
    hasIin() {
      if(!this?.form?.individual_id_number) { return false }
      return this?.form?.individual_id_number.length === 12;
    },
    showGenderField () {
      return (this.hasIin && !this.form.gender) || (this.addNewClient)
    },
    showDocTypeField() {
      return (this.hasIin && !this.form.checked) || (this.addNewClient) || this.showDocInfo
    },
    showDocNumberField() {
      return (this.hasIin && !this.form.checked) || (this.addNewClient) || this.showDocInfo
    },
    showDocIssueDateField() {
      return (this.hasIin && !this.form.checked) || (this.addNewClient) || this.showDocInfo
    },
    showAddressCityField(){
      return false;
      if(this.showCitySelect) { return true }
      if(this.addNewClient) { return true }

      if(!this.hasIin) { return false }

      if(this.hasIin && !this.form.address_city) {
        return true;
      } else {
        const addressForm = this?.$refs?.personForm?.fields?.address_city;

        if(!addressForm) { return false }

        return !(addressForm.valid && addressForm.pristine);
      }
    },

    showFullAddressField() {
      if(this.showCitySelect) { return true }
      if(this.addNewClient) { return true}
      if(!this.hasIin) { return false }

      if(this.hasIin && !this.form.address_street_full) {
        return true;
      } else {
        const addressForm = this?.$refs?.personForm?.fields?.address_street_full;

        if(!addressForm) { return false }

        return !(addressForm.valid && addressForm.pristine);
      }
    },

    showDocBlock() {
      if(this.addNewClient || this.showDocInfo) { return true }

      if(this.hasIin && !this.form.checked) {
        return true
      }

      return ((this.hasIin && !this.form.doc_number) || (this.hasIin && !this.form.doc_issue_date) || (this.hasIin && String(this.form.doc_number).length < 7))
    },

    listCities() {
      return this.$store.getters['dictionary/cityList']
    },

    personName() {
      const { fullInfo } = this

      if(!fullInfo) { return this.$i18n.t('iin'); }

      return fullInfo.fName + " " +fullInfo.lName.charAt(0) + "."
    }
  },

  created() {
    if (this.value) {
      Vue.set(this.form, 'uuid', this.value.uuid)
      Vue.set(this.form, 'client_id', this.value.manID)
      Vue.set(this.form, 'individual_id_number', this.value.individual_id_number)
      Vue.set(this.form, 'first_name', this.value.first_name)
      Vue.set(this.form, 'last_name', this.value.last_name)
      Vue.set(this.form, 's_name', this.value.s_name)
      Vue.set(this.form, 'birth_date', this.value.birth_date)
      Vue.set(this.form, 'gender', this.value.gender)
      Vue.set(this.form, 'doc_type', this.value.doc_type)
      Vue.set(this.form, 'doc_number', this.value.doc_number)
      Vue.set(this.form, 'doc_issue_date', this.value.doc_issue_date)
      // Vue.set(this.form, 'address_region', this.value.address_region)
      // Vue.set(this.form, 'address_state', this.value.address_state)
      // Vue.set(this.form, 'address_city', this.value.address_city)
      // Vue.set(this.form, 'address_street_full', this.value.address_street_full)
      // Vue.set(this.form, 'address_street_no', this.value.address_street_no)
      // Vue.set(this.form, 'address_flat_no', this.value.address_flat_no)
      Vue.set(this.form, 'checked', this.value?.checked)
      Vue.set(this.form, 'checkMessage', this.value?.checkMessage)
    }
  },

  methods: {
    showAgeRestrictionDialog() {
      this.$modal.show('dialog', {
        title: 'Уважаемый клиент!',
        text:  'Возрастное ограничения от 1 года до 63 лет.',
        buttons: [{
          title: 'OK',
          handler: () => {
            this.$modal.hide('dialog')
            return null;
          }
        }]
      });
    },
    async validateIin(iin) {
      if(!iin || iin.length !== 12) { return; }
      this.form.new_client = false
      const [resultBit, bitErrorMessage] = this.individualNumberChecker(iin);

      if(!resultBit) {
        this.resetPersonForm();
        this.clientError = bitErrorMessage
        return
      }
      this.clientError = '';
      this.form.checked = true;
      const { $loading, defaultDocType, resetPersonForm } = this
      const loader = $loading.show({});

      try{
        this.form.new_client = false

        const data = await getPeopleByIin(iin);
        const defaultDoc = data.documents.find(doc => doc.doc_type === defaultDocType) || data.documents[0];
        const defaultAddress = data.addresses.find(item => item.address_type === 'H') || data.addresses[0];
        const docDate =  defaultDoc?.doc_issue_date?.date ? moment(defaultDoc.doc_issue_date.date) : moment();


        let birthDate = data?.birthDate?.date;

        if (birthDate) {
          birthDate = moment(birthDate)
        }

        const diff =  moment().diff(birthDate, 'years');

        if(this.ageRestrictionMin && this.ageRestrictionMin > diff) {
          this.showAgeRestrictionDialog()
          return
        }
        if(this.ageRestrictionMax && this.ageRestrictionMax < diff) {
          this.showAgeRestrictionDialog()
          return
        }

        if(this.form.individual_id_number !== data.bin){
          this.form.individual_id_number = data.bin
          this.form.client_id = data.manID
          this.form.first_name = data.fName
          this.form.last_name = data.lName
          this.form.s_name = data.sName
          this.form.birth_date = birthDate
          this.form.gender = data.sex
          this.form.doc_type = defaultDoc?.doc_type
          this.form.doc_number = defaultDoc?.doc_number
          this.form.doc_issue_date = docDate.format('YYYY-MM-DD')
          this.form.address_region = defaultAddress?.region
          this.form.address_state = defaultAddress?.address_state
          this.form.address_city = defaultAddress?.address_city
          this.form.address_street_full = defaultAddress?.address_street
          this.form.address_street_no = defaultAddress?.address_street_no
          this.form.address_flat_no = defaultAddress?.address_flat_no
          this.form.checked = true

          if(!this.form.address_city) {
            this.showCitySelect = true
          } else {
            this.showCitySelect = false
          }

          if(!this.form.doc_type || !this.form.doc_number || String(this.form.doc_number).length < 7) {
            this.showDocInfo = true;
          } else {
            this.showDocInfo = false
          }
        }

        if(this.form.client_id === null || this.form.client_id === undefined){
          this.form.client_id = data.manID
        }

        if(this.form.first_name === null || this.form.first_name === undefined){
          this.form.first_name = data.first_name
        }

        if(this.form.last_name === null || this.form.last_name === undefined){
          this.form.last_name = data.last_name
        }

        if(this.form.s_name === null || this.form.s_name === undefined){
          this.form.s_name = data.s_name
        }
        if(this.form.birth_date === null || this.form.birth_date === undefined){
          this.form.birth_date = data?.birthDate.date ? moment(data?.birthDate.date) : moment()
        }

        if(this.form.gender === null || this.form.gender === undefined){
          this.form.gender = data.sex
        }

        if(this.form.doc_issue_date === null || this.form.doc_issue_date === undefined){
          this.form.doc_issue_date = docDate.format('YYYY-MM-DD')
        }

        if(this.form.doc_type === null || this.form.doc_type === undefined){
          this.form.doc_type = defaultDoc?.doc_type
        }

        if(this.form.doc_number === null || this.form.doc_number === undefined){
          this.form.doc_number = defaultDoc?.doc_number
        }

        if(this.form.doc_issue_date === null || this.form.doc_issue_date === undefined){
          this.form.doc_issue_date = docDate.format('YYYY-MM-DD')
        }

        if(this.form.address_region === null || this.form.address_region === undefined){
          this.form.address_region = defaultAddress?.address_region
        }

        if(this.form.address_state === null || this.form.address_state === undefined){
          this.form.address_state = defaultAddress?.address_state
        }

        if(this.form.address_city === null || this.form.address_city === undefined){
          this.form.address_city = defaultAddress?.address_city
        }

        if(this.form.address_street_full === null || this.form.address_street_full === undefined){
          this.form.address_street_full = defaultAddress?.address_street
        }

        if(this.form.address_street_no === null || this.form.address_street_no === undefined){
          this.form.address_street_no = defaultAddress?.address_street_no
        }

        if(this.form.address_flat_no === null || this.form.address_flat_no === undefined){
          this.form.address_flat_no = defaultAddress?.address_flat_no
        }

        this.fullInfo = data;
      } catch (err) {
        if(err?.response?.data.error_code === "5054") {
          resetPersonForm()
          this.form.individual_id_number = iin
          this.form.new_client = true
          return;
        }

        if (err?.statusCode === 'DUPLICATE_ITEM') {
          this.clientError = this.$i18n.t('driverAlreadyAdded');
        } else if (err?.value) {
          this.clientError = err.value
        } else if(err?.response?.data?.message) {
          this.clientError = err?.response?.data?.message
        } else {
          this.clientError = this.$i18n.t('serverError');
        }

        // Обнуляем форму
        resetPersonForm()

        this.$emit('error', {
          title: `Ошибка при получение данных клиента, ${iin}`,
          message: (err?.response?.data?.message || err?.message),
          code: err?.response?.data?.error_code || 0
        });

        eventLogger('ogpo_fail', {description: "Ошибка при заполнении полей и ошибки гос баз при автостраховании"})

      } finally {
        loader.hide()
      }
    },
    removeItem() {
      this.$emit('removeItem', this.index);
    },
    onAddressChange (val) {
      const { listCities } = this;

      const cityObj = listCities.find(item => item.city === val)

      if(cityObj) {
        this.form.address_region = cityObj.region
        this.form.address_state = cityObj.state
        this.form.address_city = cityObj.city
      }
    },
    resetPersonForm() {
      this.form.individual_id_number = null;
      this.form.first_name = null;
      this.form.last_name = null
      this.form.s_name = null
      this.form.birth_date = null
      this.form.gender = null
      this.form.doc_type = null
      this.form.doc_number = null
      this.form.doc_issue_date = null
      this.form.address_region = null
      this.form.address_state = null
      this.form.address_city = null
      this.form.address_street_full = null
      this.form.address_street_no = null
      this.form.address_flat_no = null
      this.fullInfo = null
    },

    scrollToThis() {
      this.$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    },
    individualNumberChecker(iin) {
      let s = 0;
      const nn = iin.split("");
      for (var i = 0; i < 11; i++) {
        s = s + (i + 1) * nn[i];
      }
      let k = s % 11;
      if (k == 10) {
        s = 0;
        for (var i = 0; i < 11; i++) {
          var t = (i + 3) % 11;
          if (t == 0) {
            t = 11;
          }
          s = s + t * nn[i];
        }
        k = s % 11;
        if (k == 10)
          return [
            false, "ИИН не должен использоваться, ошибки при формировании контрольного разряда",
          ];
        return [
          k == iin.substr(11, 1), "Контрольный разряд ИИН неверный, должно быть " + k + ", а стоит " + iin.substr(11, 1),
        ];
      }
      return [
        k == iin.substr(11, 1), "Контрольный разряд ИИН неверный, должно быть " + k + ", а стоит " + iin.substr(11, 1),
      ];
    },
  },

  watch: {
    form: {
      deep: true,
      handler (changed) {
        this.$emit('changed', { item: changed, index: this.index })
      }
    }
  }
}
</script>

<style scoped>
.close-btn {
  position: absolute;
  z-index: 20;
  right: 12px;
  width: 24px;
  top: 24px;
}
</style>
