<template>
  <div class="primary-block">
    <h3 class="input-list-title">{{$t('addCascoLight')}}</h3>
    <ul class="input-list">
      <li class="input-list-item">
        <label class="primary-text label">
          {{$t('cascoLight')}} <span class="success-text">{{$t('details')}}</span></label>
        <input type="checkbox">
      </li>
      <li class="input-list-item" @click="isSelectOptionsShown = !isSelectOptionsShown">
        <p class="primary-text">
          {{$t('premium')}}
          <span class="success-text">{{ activeOption === null ? selectOptions[0] : activeOption }}</span>
        </p>
        <div>
          <icon icon-name="select-arrow-icon" class="arrow-down" :class="{'inverted': isSelectOptionsShown}"></icon>
        </div>
      </li>
    </ul>
    <p class="secondary-text mb-0">{{$t('insuranceLimit')}} <span class="success-text">100 000 ₸.</span></p>
    <ul class="options-list" :class="{'active': isSelectOptionsShown}">
      <li v-for="(item, index) in selectOptions" :key="index" class="options-list-item" @click="selectOption(item)">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CheckboxAndSelect",
  props: {
    selectOptions: {
      type: Array
    }
  },
  data() {
    return {
      isSelectOptionsShown: false,
      activeOption: null,
    }
  },
  methods: {
    selectOption(item) {
      this.activeOption = item;
      this.isSelectOptionsShown = false;
    }
  }
}
</script>

<style scoped>

</style>
