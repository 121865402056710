<template>
  <div class="primary-block">
    <h3 class="input-list-title" v-if="title">{{ title }}</h3>
    <h4 class="danger-text">{{ error }}</h4>
    <ul class="input-list">
      <li
        class="input-list-item"
        v-for="(country, index) in countries"
        :key="index"
      >
        <div>
          <span class="primary-text">{{ country.name }}</span>
        </div>
        <div @click="$emit('delete', index)">
          <icon icon-name="close-button-icon" />
        </div>
      </li>

<!--      <search-select-->
<!--        v-if="addNewCountrySelectorShow"-->
<!--        :options="countriesList"-->
<!--        :is-block="true"-->
<!--        label="name"-->
<!--        track-by="value"-->
<!--        name="country"-->
<!--        rules="required"-->
<!--        :reduce="c => c.value"-->
<!--        v-model="country"-->
<!--        :placeholder="$t('countryDestination')"-->
<!--        @focusOut="addNewCountrySelectorShow=false"-->
<!--      />-->

      <div
        @click="addNewCountryField()"
        :class="['input-add-id', { 'input-add-disabled': addBtnDisabled }]"
      >
        <div class="icon-box">
          <icon icon-name="plus-icon"></icon>
        </div>
        <span class="text">{{ buttonTitle }}</span>
      </div>
    </ul>
  </div>
</template>

<script>
import CountrySelectModal from "../Modals/CountrySelectModal";
export default {
  name: "AddCountry",
  components: {CountrySelectModal},
  props: {
    countries: {
      type: Array,
    },
    countriesList: {
      type: Array,
    },
    error: {
      type: String,
    },
    title: {
      type: String,
    },
    buttonTitle: {
      type: String,
    },
    addBtnDisabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      country: null,
      addNewCountrySelectorShow: false
    };
  },
  watch: {
    country(val) {
      if (val !== null) {
        this.addCountry(
          this.countriesList.find((country) => country.id === val)
        );
      }
      this.country = null;
    },
  },
  methods: {
    addNewCountryField() {
      if(this.addBtnDisabled) {
        return;
      }
      this.$modal.show(CountrySelectModal, {
        countriesList: this.countriesList,
      }, this.$modalConfig, {
        'before-close': (event) => {
          if(event.params) {
            this.addNewCountrySelectorShow = false
            this.$emit("input", event.params);
          }
        }
      })
    },
  },
};
</script>
