<template>
  <modal-layout @close="$emit('close')" :title="$t('Выберите страну поездки')" :full-height="true">
    <div class="modal-content">
      <country-list :countries-data="countriesList" @onchange="countrySelected"/>
    </div>
  </modal-layout>
</template>


<script>
import SearchSelect from "../BuyPolicy/SearchSelect";
import CountryList from "../CountryList";
export default {
  name: "CountrySelectModal",
  components: {CountryList, SearchSelect},
  props: ['countriesList'],
  data() {
    return {
      errorMessage: null,
      country: null
    }
  },
  computed: {
  },
  methods: {
    countrySelected(val) {
      this.$emit('close', val)
    }
  }
}
</script>

<style scoped>
.modal-body{
  height: calc(100vh - 100px);
}
</style>