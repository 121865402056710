<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ invalid, errors }">
        <div>
          <input-field
              :label="$t('phoneNumber')"
              v-model="phone_number"
              field-type="tel"
              :is-block="true"
              rules="required|numeric|min:10"
              name="phone"
              :addition="$t('cantChangeNumber')"
              mask="+7(###) ###-##-##"
              :disabled="true"
          />
        </div>

        <select-date
            :is-block="true"
            rules="required"
            name="start_date"
            :value="startDate"
            :until="initialDate"
            @input="changeStartDate"
            :title="$t('policyStartDate')"
            :is-disabled-before-today="true"
        />

        <PersonInfo v-if="holder" :value="holder" @changed="changeHolder" :key="holder.uuid" @error="onError"/>

<!--        <radio-input-list-->
<!--            :read-more="false"-->
<!--            :options="monthsOptions"-->
<!--            radio-name="periods"-->
<!--        />-->
      
        <box-product-bonuses :available-products="availableBonuses" v-model="bonusProduct"/>

      <div class="d-flex">
        <ValidationProvider :rules="{ required: { allowFalse: false } }" name="terms">
          <input type="checkbox" id="terms" class="me-4" name="terms" v-model="i_agree">
        </ValidationProvider>
        <label for="terms" class="terms-text">Я принимаю условия <span style="color: #2aa65c;" @click.prevent="showTermsModal">пользовательского соглашения</span> и даю свое согласие на сбор и обработку персональных данных</label>
      </div>
      <swipe-modal
          v-model="isModal"
          contents-height="50vh"
          border-top-radius="16px"
          :persistent="true"
          backgroundColor="#F7F7F7"
          tip-color="#c8c8c8"
          contents-color="#F7F7F7"
      >
        <sos-feedback></sos-feedback>
      </swipe-modal>
        <div class="final-amount-wrapper">
          <final-amount
              v-if=" i_agree"
              :bonus-title="bonusTitle"
              :bonus-value="bonusValue"
              :amount="amount" :fix-sum="amount" :invalid="invalid" @submit="onSubmit"/>
        </div>
    </ValidationObserver>
  </div>
</template>

<script>
import InputField from "@/components/BuyPolicy/InputField";
import {mapActions, mapGetters} from "vuex";
import FinalAmount from "../BuyPolicy/FinalAmount";
import {cheerUpCalculation} from "../../api/calculations";
import ePayPayment from "../../mixin/ePayPayment";
import eventLogger from '../../services/eventLogger';
import PersonInfo from "../PersonInfo";
import SelectDate from "../BuyPolicy/SelectDate";
import moment from "moment";
import RadioInputList from "../BuyPolicy/RadioInputList";
import {uuidv4} from "../../plugins/Helpers";
import commonFormData from "../../mixin/commonFormData";
import redmine from "../../mixin/redmine";
import BoxProductBonuses from "../BuyPolicy/BoxProductBonuses";
import TermsModal from "../Modals/TermsModal";
import swipeModal from '@takuma-ru/vue-swipe-modal'
import SosFeedback from "../../views/SosFeedback";

export default {
  name: "CheerUpForm",
  mixins: [ePayPayment, commonFormData, redmine],
  components: {
    BoxProductBonuses,
    PersonInfo,
    InputField,
    FinalAmount,
    SelectDate,
    RadioInputList,
    swipeModal,
    SosFeedback
  },
  data() {
    return {
      isModal:false,
      i_agree:false,
      amount: 15000,
      monthsOptions: [
        { name: this.$i18n.t('month12'), value: 12 },
      ],
    }
  },
  mounted() {
    eventLogger('view_finance_insurance')
  },
  computed: {
    ...mapGetters({
      holder: 'cheerUpForm/holder',
      startDate: 'cheerUpForm/startDate',
      form: 'cheerUpForm/finalFormValue'
    }),
    finalAmount() {
      return this.amount
    },
    clientsChecked(){
      let checked = true;

      if(this.holder.checked === false) {
        checked = false
      }
      return checked
    },

    bonusProduct: {
      get() {
        return this.$store.getters['cheerUpForm/bonusId'];
      },
      set(payload) {
        this.changeSelectedBonus(payload)
      }
    }
  },
  methods: {
    ...mapActions({
      changeStartDate: 'cheerUpForm/changeStartDate',
      changeHolder: 'cheerUpForm/changeHolder',
      resetForm: 'cheerUpForm/resetForm',
      changeSelectedBonus: 'cheerUpForm/changeSelectedBonus'
    }),
    showTermsModal() {
      this.$modal.show(TermsModal, {}, this.$modalConfig);
    },
    async setDefaultInfo(){
      const { authUser, holder } = this;

      if(holder === null || !this.holder.individual_id_number) {
        this.$store.commit("cheerUpForm/CHANGE_HOLDER", {
          uuid: uuidv4(),
          individual_id_number: authUser.individual_id_number
        })
      }

      this.setInitialDate(moment().add(1, "days").format("YYYY-MM-DD"))
    },

    async setAgreementInfo() {
      this.agreement_id = this.$route.query.agreement_id;

      const { data } = await window.axios.get(`policies/${this.agreement_id}`);

      this.setInitialDate(data.endDate.date)

      await this.$store.commit("cheerUpForm/CHANGE_HOLDER", {
        uuid: uuidv4(),
        individual_id_number: data.holder.bin
      });

    },

    async calculation() {
      const data = await cheerUpCalculation(this.form);

      // Логирование
      eventLogger('select_payment_finance', {payment_sum: this.amount})

      return data;
    },

    async checkClients() {
      this.$store.commit('cheerUpForm/SET_HOLDER_CHECK_MESSAGE', null)

      try {
        await window.axios.post(`/verified-client-csdb`, this.holder)

        this.$store.commit('cheerUpForm/SET_CHECK_HOLDER', true)
      } catch (e) {
        const error = {
          message: e?.response?.data?.message || 'Ошибка при сверке клиента',
          code: e?.response?.data?.error_code || 0,
          title: e?.response?.data?.message || 'Ошибка при сверке клиента',
        }
        this.onError(error)
        this.isModal = true
        this.$store.commit('cheerUpForm/SET_HOLDER_CHECK_MESSAGE', e?.response?.data?.message)
        this.$store.commit('cheerUpForm/SET_CHECK_HOLDER', false)
      }
    }
  }
}
</script>

<style scoped>

</style>
