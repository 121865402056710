<template>
  <ValidationObserver ref="touristForm">
    <radio-input-list
        :title="title"
        :read-more="false"
        :options="tourist_types"
        :radio-name="`is_resident_${value.uuid}`"
        v-model="form.foreigner"
    />
    <div class="position-relative" v-if="isResident">
      <input-field
          :label="personName"
          :is-block="true"
          name="iin"
          field-type="tel"
          rules="required|min:12"
          :value="iin"
          @input="validateIin"
          mask="############"
          class-list="person-iin"
      />

      <div class="addition-invalid" v-if="clientError">
        <icon icon-name="read-info-icon" class="me-2"></icon>
        <span class="">{{ clientError }}</span>
      </div>

      <search-select
          v-show="this.hasIin && !form.gender"
          :options="genders"
          :placeholder="$t('sex')"
          :reduce="gender => gender.value"
          :is-block="true"
          label="name"
          name="gender"
          v-model="form.gender"
      />

      <search-select
          v-show="this.hasIin && !form.address_city"
          label="title"
          name="city"
          rules="required"
          :value="form.address_city"
          :options="listCities"
          :reduce="item => item.city"
          :placeholder="$t('city')"
          :is-block="true"
          @input="onAddressChange"
      />

      <input-field
          v-show="showFullAddressField"
          :label="$t('street')"
          rules="required"
          name="address_street_full"
          v-model="form.address_street_full"
          :is-block="true"
      />

      <search-select
          v-show="showDocBlock"
          :options="documents"
          :is-block="true"
          :reduce="item => item.value"
          label="name"
          name="selected_doc"
          rules="required"
          :placeholder="$t('docType')"
          v-model="form.doc_type"
          :no-drop="true"
          :searchable="false"
      />

      <input-field-list :title="$t('passportConfirm')" v-show="showDocBlock">
        <span style="color: red" v-if="checkMessage">
          {{ checkMessage }}
        </span>
        <input-field
            :label="$t('docNumber')"
            rules="required"
            name="doc_number"
            v-model="form.doc_number"/>

        <select-date
            v-model="form.doc_issue_date"
            rules="required"
            name="doc_issue_date"
            :title="$t('docGiveDate')"
        />
      </input-field-list>
    </div>

    <input-field
        v-if="!isResident || form.individual_id_number"
        :label="$t('lastNameInLatin')"
        field-type="text"
        :is-block="true"
        v-model="form.last_name_latin"
        name="lastNameOnLatin"
        :uppercase="true"
    />
    <input-field
        v-if="!isResident || form.individual_id_number"
        :label="$t('firstNameInLatin')"
        field-type="text"
        :is-block="true"
        v-model="form.first_name_latin"
        name="firstNameOnLatin"
        :uppercase="true"
    />

    <template v-if="!isResident">
      <search-select
          v-show="!form.gender"
          :options="genders"
          :placeholder="$t('sex')"
          :reduce="gender => gender.value"
          :is-block="true"
          label="name"
          name="gender"
          v-model="form.gender"
      />
      <select-date
          v-model="form.birth_date"
          :is-block="true"
          rules="required"
          name="birth_date"
          :title="$t('dateOfBirth')"
      />
      <search-select
          label="title"
          name="country_id"
          rules="required"
          :options="countries"
          v-model="form.country_id"
          :reduce="item => item.value"
          :placeholder="$t('country')"
          :is-block="true"
      />

      <search-select
          :options="documents"
          :is-block="true"
          :reduce="item => item.value"
          label="name"
          name="selected_doc"
          rules="required"
          :placeholder="$t('docType')"
          v-model="form.doc_type"
          :no-drop="true"
          :searchable="false"
      />

      <input-field-list :title="$t('passportConfirm')">
        <span style="color: red" v-if="checkMessage">
          {{ checkMessage }}
        </span>
        <input-field
            :label="$t('docNumber')"
            rules="required"
            name="doc_number"
            v-model="form.doc_number"/>

        <select-date
            v-model="form.doc_issue_date"
            rules="required"
            name="doc_issue_date"
            :title="$t('docGiveDate')"
        />
      </input-field-list>
    </template>
    <remove-button
        v-if="canDelete"
        @click.native="removeItem"
        :text="$t('removeTourist')"
    />
  </ValidationObserver>
</template>

<script>

import InputField from "./BuyPolicy/InputField";
import SearchSelect from "./BuyPolicy/SearchSelect";
import InputFieldList from "./BuyPolicy/InputFieldList";
import SelectDate from "./BuyPolicy/SelectDate";
import {getPeopleByIin} from "../api/info";
import moment from "moment";
import Vue from "vue";
import RadioInputList from "./BuyPolicy/RadioInputList";
import {qazTranslit} from "../plugins/Helpers";
import RemoveButton from "./RemoveButton";
import {mapGetters} from "vuex";

export default {
  name: "TouristInfo",
  components: {
    RemoveButton,
    RadioInputList,
    InputField,
    SearchSelect,
    InputFieldList,
    SelectDate
  },
  props: {
    title: {
      type: String,
      default: function(){
        return this.$i18n?.t('mainPolicyHolder')
      }
    },
    defaultDocType: {
      type: String,
      default: "PS"
    },
    defaultDocRequired: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    value: Object,
  },
  data(){
    return {
      iin: this?.value?.individual_id_number,
      form: {
        individual_id_number: null,
        gender: null,
        doc_type: "PS",
        doc_number: null,
        doc_issue_date: null,
        address_region: null,
        address_state: null,
        address_city: null,
        address_street_full: null,
        address_street_no: null,
        address_flat_no: null,
        first_name_latin: null,
        last_name_latin: null,
        checked: true,
        checkMessage: null,
        foreigner: 'N',
        country_id: null,
        birth_date: null
      },
      documents: [
        // {name: this.$i18n.t('kazID'), value: 'IC'},
        {name: this.$i18n.t('passport'), value: 'PS'},
        // {name: this.$i18n.t('birthCertificate'), value: 'BC'},
        // {name: this.$i18n.t('nonResidentPermit'), value: 'RPF'},
        // {name: this.$i18n.t('driverLicense'), value: 'DL'},
      ],
      genders: [
        { name: this.$i18n.t('male'), value: 1 },
        { name: this.$i18n.t('female'),value: 2 },
      ],
      tourist_types: [
        { name: this.$i18n.t('resident'), id: 'N' },
        { name: this.$i18n.t('nonResident'), id: 'Y' },
      ],
      fullInfo: null,
      clientError: null,
    }
  },
  computed: {
    ...mapGetters({
      countries: 'dictionary/countries'
    }),
    hasIin() {
      if(!this?.form?.individual_id_number) { return false }

      return this?.form?.individual_id_number.length === 12;
    },

    showFullAddressField() {
      if(!this.hasIin) { return false }

      if(this.hasIin && !this.form.address_street_full) {
        return true;
      } else {
        const addressForm = this?.$refs?.personForm?.fields?.address_street_full;
        if(!addressForm) { return false }

        return !(addressForm.valid && addressForm.pristine);
      }
    },

    showDocBlock() {
      if(this.hasIin && !this.form.checked) {
        return true
      }
      if((this.hasIin && !this.form.doc_number)) {
        return true;
      }
      return (this.hasIin && !this.form.doc_issue_date);
    },

    listCities() {
      return this.$store.getters['dictionary/cityList']
    },

    personName() {
      const { fullInfo } = this
      if(!fullInfo) { return this.$i18n.t('iin'); }

      return fullInfo.fName + " " +fullInfo.lName.charAt(0) + "."
    },

    checkMessage() {
      return this.form.checkMessage
    },

    isResident() {
      return this.form.foreigner === 'N'
    }
  },

  mounted() {
    if (this.value) {
      Vue.set(this.form, 'uuid', this.value.uuid)
      Vue.set(this.form, 'individual_id_number', this.value.individual_id_number)
      Vue.set(this.form, 'gender', this.value.gender)
      Vue.set(this.form, 'doc_type', this.value.doc_type)
      Vue.set(this.form, 'doc_number', this.value.doc_number)
      Vue.set(this.form, 'doc_issue_date', this.value.doc_issue_date)
      Vue.set(this.form, 'address_region', this.value.address_region)
      Vue.set(this.form, 'address_state', this.value.address_state)
      Vue.set(this.form, 'address_city', this.value.address_city)
      Vue.set(this.form, 'address_street_full', this.value.address_street_full)
      Vue.set(this.form, 'address_street_no', this.value.address_street_no)
      Vue.set(this.form, 'address_flat_no', this.value.address_flat_no)
      Vue.set(this.form, 'checked', this.value?.checked)
      Vue.set(this.form, 'checkMessage', this.value?.checkMessage)
    }
  },
  methods: {
    async validateIin(iin) {
      if(!iin || iin.length !== 12) { return; }

      this.clientError = '';
      this.form.checked = true;
      const { $loading, defaultDocType, resetPersonForm } = this
      const loader = $loading.show({});

      try{
        const data = await getPeopleByIin(iin);

        let defaultDoc = data.documents.find(doc => doc.doc_type === defaultDocType)

        if(!defaultDoc) {
          defaultDoc = {doc_type: defaultDocType, doc_number: null, doc_issue_date: null};
          this.form.checked = false
        }
        // const defaultDoc = data.documents.find(doc => doc.doc_type === defaultDocType) || (this.defaultDocRequired ? {doc_type: this.defaultDocType} : data.documents[0]);
        const defaultAddress = data.addresses.find(item => item.address_type === 'H') || data.addresses[0];
        const docDate =  defaultDoc?.doc_issue_date?.date ? moment(defaultDoc.doc_issue_date.date) : moment();

        if(this.form.individual_id_number !== data.bin){
          this.form.individual_id_number = data.bin
          this.form.gender = data.sex
          this.form.doc_type = defaultDoc?.doc_type
          this.form.doc_number = defaultDoc?.doc_number
          this.form.doc_issue_date = docDate.format('YYYY-MM-DD')
          this.form.address_region = defaultAddress?.region
          this.form.address_state = defaultAddress?.address_state
          this.form.address_city = defaultAddress?.address_city
          this.form.address_street_full = defaultAddress?.address_street
          this.form.address_street_no = defaultAddress?.address_street_no
          this.form.address_flat_no = defaultAddress?.address_flat_no
          this.form.checked = !!defaultDoc.doc_number
        }

        if(this.form.gender === null || this.form.gender === undefined){
          this.form.gender = data.sex
        }

        if(this.form.doc_type === null || this.form.doc_type === undefined){
          this.form.doc_type = defaultDoc?.doc_type
        }

        if(this.form.doc_number === null || this.form.doc_number === undefined){
          this.form.doc_number = defaultDoc?.doc_number
        }

        if(this.form.doc_issue_date === null || this.form.doc_issue_date === undefined){
          this.form.doc_issue_date = docDate.format('YYYY-MM-DD')
        }

        if(this.form.address_region === null || this.form.address_region === undefined){
          this.form.address_region = defaultAddress?.address_region
        }

        if(this.form.address_state === null || this.form.address_state === undefined){
          this.form.address_state = defaultAddress?.address_state
        }

        if(this.form.address_city === null || this.form.address_city === undefined){
          this.form.address_city = defaultAddress?.address_city
        }

        if(this.form.address_street_full === null || this.form.address_street_full === undefined){
          this.form.address_street_full = defaultAddress?.address_street
        }

        if(this.form.address_street_no === null || this.form.address_street_no === undefined){
          this.form.address_street_no = defaultAddress?.address_street_no
        }

        if(this.form.address_flat_no === null || this.form.address_flat_no === undefined){
          this.form.address_flat_no = defaultAddress?.address_flat_no
        }

        this.fullInfo = data;
      } catch (err) {
        if (err?.statusCode === 'DUPLICATE_ITEM') {
          this.clientError = this.$i18n.t('driverAlreadyAdded');
        } else if (err?.value) {
          this.clientError = err.value
        } else if(err?.response?.data?.message) {
          this.clientError = err?.response?.data?.message
        } else {
          this.clientError = this.$i18n.t('serverError');
        }

        // Обнуляем форму
        resetPersonForm()

        this.$emit('error', {
          title: `Ошибка при получение данных клиента, ${iin}`,
          message: (err?.response?.data?.message || err?.message),
          code: err?.response?.data?.error_code || 0
        });
      } finally {
        loader.hide()
      }
    },
    removeItem() {
      this.$emit('removeItem', this.index);
    },
    onAddressChange (val) {
      const { listCities } = this;

      const cityObj = listCities.find(item => item.city === val)

      if(cityObj) {
        this.form.address_region = cityObj.region
        this.form.address_state = cityObj.state
        this.form.address_city = cityObj.city
      }
    },
    resetPersonForm() {
      this.form.individual_id_number = null;
      this.form.gender = null
      this.form.doc_type = "PS"
      this.form.doc_number = null
      this.form.doc_issue_date = null
      this.form.address_region = null
      this.form.address_state = null
      this.form.address_city = null
      this.form.address_street_full = null
      this.form.address_street_no = null
      this.form.address_flat_no = null
      this.form.first_name_latin = null
      this.form.last_name_latin = null
      this.form.country_id = null
      this.form.birth_date = null
      this.fullInfo = null
    }
  },

  watch: {
    'form.foreigner':{
        handler(changed) {
          this.resetPersonForm();
        }
    },
    form: {
      deep: true,
      handler (changed) {
        this.$emit('changed', { item: changed, index: this.index })
      }
    },

    fullInfo: {
      deep: true,
      immediate: true,

      handler(data) {
        if(data && data.fName && data.lName) {
          this.form.first_name_latin = qazTranslit(data.fName).toUpperCase()
          this.form.last_name_latin = qazTranslit(data.lName).toUpperCase()
        }
      }
    }
  }
}
</script>

<style scoped>
.close-btn {
  position: absolute;
  z-index: 20;
  right: 12px;
  width: 24px;
  top: 24px;
}
</style>
