<template>
    <div v-if="bonusOptions.length">
      <div class="primary-block">
        <h2 class="input-list-title">{{ $t('selectBonusProduct') }}</h2>
        <ul class="input-list">
          <li
              class="input-list-item"
              v-for="(option, index) in bonusOptions"
              :key="index"
          >
            <div>
              <label class="primary-text label mb-0" :for="`bonusProduct_${option.id}`">{{ getOptionLabel(option) }}
              </label>
              <span class="success-text" @click="showBonusDetail(option)">{{$t('details')}}</span>
            </div>
            <input
                type="radio"
                name="box_bonus_product_radio"
                :value="option.value"
                :id="`bonusProduct_${option.id}`"
                v-model="product"
                :checked="selectedProductId == option.value"
            />
          </li>
        </ul>
      </div>
    </div>
</template>
<script>
import RadioInputList from './RadioInputList.vue';
import BoxProductsBonusModal from "../Modals/BoxProductsBonusModal";
import {mapGetters} from "vuex";

export default {
  components: { RadioInputList },
  name: "BoxProductBonuses",
  props: {
    availableProducts: Array
  },
  data() {
    return {
      selectedProductId: null
    };
  },
  mounted() {
    if(this.bonusOptions) {
      this.$nextTick(() => {
        this.product = this.bonusOptions[0].value
      })
    }
  },
  computed:{
    ...mapGetters(['getAltTranslationFieldSuffix']),
    product: {
      get() {
        return this.selectedProductId;
      },
      set(productId) {
        this.selectedProductId = productId

        this.$emit('input', productId)
      }
    },
    bonusOptions(){
      if(this.availableProducts && this.availableProducts.length) {
        return this.availableProducts.map(item => ({value: item.insisID, name: item.title}))
      } else {
        return []
      }
    }
  },
  methods: {
    showBonusDetail(product) {
      const info = this.availableProducts.find(item => item.insisID == product.value)

      this.$modal.show(BoxProductsBonusModal, { info }, this.$modalConfig)
    },
    getOptionLabel (option) {
      const { getAltTranslationFieldSuffix } = this;

      if(option.mergeProduct) {
        return option['mergeProduct']['name' + getAltTranslationFieldSuffix] || option['name'];
      }

      return option['name'] || option['title'];
    }
  },

};
</script>
