<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ invalid, errors }">
      <div>
        <input-field
            :label="$t('phoneNumber')"
            v-model="phone_number"
            field-type="tel"
            :is-block="true"
            rules="required|numeric|min:10"
            name="phone"
            mask="+7(###) ###-##-##"
            :disabled="true"
        />
      </div>

        <PersonInfo
            v-if="holder"
            :value="holder"
            @changed="changeHolder"
            :key="holder.uuid"
            @error="onError"
            rules="required"
            :ref="holder.uuid"
        />

        <!-- <radio-input-list
            :read-more="false"
            :options="monthsOptions"
            radio-name="periods"
        /> -->

        <div v-if="holder && holder.individual_id_number && holder.individual_id_number.length === 12">
<!--          <checkbox-list :options="ownerIsHolderProp" name="owner_is_holder" v-model="ownerIsHolder"/>-->

          <PersonInfo
              v-if="!ownerIsHolder"
              :value="propertyOwner"
              :key="propertyOwner.uuid"
              @changed="changePropertyOwner"
              @error="onError"
              :ref="propertyOwner.uuid"
          />

          <div class="insured-property-select-wrap" v-if="holder.individual_id_number && (ownerIsHolder || (!ownerIsHolder && propertyOwner && propertyOwner.individual_id_number))">
            <InsuredProperty :value="insuredProperty" @changed="changeInsuredProperty" @toDateField="toDateField"/>

            <select-date
               :value="startDate"
               @input="changeStartDate"
               :title="$t('policyStartDate')"
               rules="required"
               name="policy_date"
               :until="initialDate"
               :is-disabled-before-today="true"
               :is-block="true"
               ref="startDateInputField"
            />
          </div>
        </div>


        <box-product-bonuses :available-products="availableBonuses" v-model="bonusProduct"/>
      <div class="d-flex">
        <ValidationProvider :rules="{ required: { allowFalse: false } }" name="terms">
          <input type="checkbox" id="terms" class="me-4" name="terms" v-model="i_agree">
        </ValidationProvider>
        <label for="terms" class="terms-text">Я принимаю условия <span style="color: #2aa65c;" @click.prevent="showTermsModal">пользовательского соглашения</span> и даю свое согласие на сбор и обработку персональных данных</label>
      </div>
        <div class="final-amount-wrapper">
          <final-amount
              v-if=" i_agree"
              :bonus-title="bonusTitle"
              :bonus-value="bonusValue"
              @submit="onSubmit" :amount="amount" :fix-sum="amount" :invalid="invalid"/>
        </div>
    </ValidationObserver>
  </div>
</template>

<script>
import InputField from "@/components/BuyPolicy/InputField";
import SelectDate from "../BuyPolicy/SelectDate";
import {mapActions, mapGetters} from "vuex";
import CheckboxList from "../BuyPolicy/CheckboxList";
import FinalAmount from "../BuyPolicy/FinalAmount";
import {realEstateCalculation} from "../../api/calculations";
import ePayPayment from "../../mixin/ePayPayment";
import eventLogger from '../../services/eventLogger';
import PersonInfo from "../PersonInfo";
import RadioInputList from "../BuyPolicy/RadioInputList";
import InsuredProperty from "../InsuredProperty";
import moment from "moment";
import {uuidv4} from "../../plugins/Helpers";
import commonFormData from "../../mixin/commonFormData";
import redmine from "../../mixin/redmine";
import BoxProductBonuses from "../BuyPolicy/BoxProductBonuses";
import SelectRate from "../BuyPolicy/SelectRate";
import TermsModal from "../Modals/TermsModal";

export default {
  name: "FlatOnlineForm",
  mixins: [ePayPayment, commonFormData, redmine],
  components: {
    BoxProductBonuses,
    InsuredProperty,
    PersonInfo,
    CheckboxList,
    InputField,
    SelectDate,
    FinalAmount,
    RadioInputList,
    SelectRate
  },
  data() {
    return {
      i_agree:false,
      amount: 15000,
      monthsOptions: [{ name: this.$i18n.t('month12'), value: 12 }],
      ownerIsHolderProp: [
        {
          name: this.$i18n.t('insuredAndPolicyHolderMatch'),
          value: this.ownerIsHolder,
        }
      ],
    }
  },
  async created() {
    eventLogger('view_apartment_insurance')

    const { getUnionAddressDictionary, $loading } = this;

    const loader = $loading.show();

    try{
      await getUnionAddressDictionary();
    } catch (err) {
      this.onError({
        title: `Ошибка при получение данных справочника`,
        message: (err?.response?.data?.message || err?.message),
        code: err?.response?.data?.error_code || 0
      })
    }

    loader.hide()
  },
  computed: {
    ...mapGetters({
      holder: 'flatForm/holder',
      propertyOwner: 'flatForm/propertyOwner',
      insuredProperty: 'flatForm/insuredProperty',
      startDate: "flatForm/startDate",
      form: 'flatForm/finalFormValue',
    }),
    ownerIsHolder: {
      get() {
        return this.$store.getters['flatForm/ownerIsHolder'];
      },
      set(val) {
        this.changeOwnerIsHolder(val);
      }
    },
    finalAmount() {
      return this.amount
    },
    bonusProduct: {
      get() {
        return this.$store.getters['flatForm/bonusId'];
      },
      set(payload) {
        this.changeSelectedBonus(payload)
      }
    },
    clientsChecked() {
      let checked = true;

      if(this.holder.checked === false) {
        checked = false
      }

      if(this.ownerIsHolder === false && this.propertyOwner.checked === false) {
        checked = false
      }

      return checked
    },

  },
  methods: {
    ...mapActions({
      changeStartDate: 'flatForm/changeStartDate',
      changeOwnerIsHolder: 'flatForm/changeOwnerIsHolder',
      changeHolder: 'flatForm/changeHolder',
      changePropertyOwner: 'flatForm/changePropertyOwner',
      changeInsuredProperty: 'flatForm/changeInsuredProperty',
      resetForm: 'flatForm/resetForm',
      getUnionAddressDictionary: 'dictionary/getUnionAddressDictionary',
      changeSelectedBonus: 'flatForm/changeSelectedBonus'
    }),
    showTermsModal() {
      this.$modal.show(TermsModal, {}, this.$modalConfig);
    },
    toDateField() {
      this.$refs.startDateInputField.$el.scrollIntoView({behavior: "smooth", block: 'start'})
    },

    async calculation() {
      const data =  await realEstateCalculation(this.form)

      // Логирование
      eventLogger('select_payment_apartment', { payment_sum: this.amount })

      return data;
    },

    async setDefaultInfo() {
      const { authUser, holder } = this;

      // Если нет держателя то записываем текущего пользователя
      if(holder === null || !this.holder.individual_id_number) {
        this.changeHolder({ item: { uuid: uuidv4(),individual_id_number: authUser.individual_id_number }})
      }

      this.setInitialDate(moment().add(1, "days").format("YYYY-MM-DD"))
    },

    async setAgreementInfo() {
      this.agreement_id = this.$route.query.agreement_id;

      const { data } = await window.axios.get(`policies/${this.agreement_id}`);

      const object = data?.objects[0];

      await this.$store.commit("flatForm/SET_HOLDER", {
        uuid: uuidv4(),
        individual_id_number: data.holder.bin
      });

      this.setInitialDate(data.endDate.date)

      if(object) {
        const foundCity = this.listCities.find(item => item.city === object.city);

        if (foundCity) {
          this.changeInsuredProperty({
            country: "KAZ",
            state: String(foundCity?.state),
            region: String(foundCity?.region),
            city: String(foundCity?.city),
            street_name: object.street_name,
            street_no: object.blockNumber,
            flat_no: object.apartmentNumber,
          })
        }
      }
    },

    async checkClients() {
      this.$store.commit('flatForm/SET_HOLDER_CHECK_MESSAGE', null);

      try {
        await window.axios.post(`/verified-client-csdb`, this.holder)

        this.$store.commit('flatForm/SET_CHECK_HOLDER', true);
      } catch (e) {
        const error = {
          message: e?.response?.data?.message || 'Ошибка при сверке клиента',
          code: e?.response?.data?.error_code || 0,
          title: e?.response?.data?.message || 'Ошибка при сверке клиента',
        }
        this.onError(error)
        this.$store.commit('flatForm/SET_CHECK_HOLDER', false);
        this.$store.commit('flatForm/SET_HOLDER_CHECK_MESSAGE', e?.response?.data?.message);
        this.$refs[this.holder.uuid].scrollToThis();
      }

      if(!this.ownerIsHolder) {
        this.$store.commit('flatForm/SET_OWNER_CHECK_MESSAGE',null);

        try {
          await window.axios.post(`/verified-client-csdb`, this.propertyOwner)

          this.$store.commit('flatForm/SET_CHECK_OWNER', true);
        } catch (e) {
          const error = {
            message: e?.response?.data?.message || 'Ошибка при сверке клиента',
            code: e?.response?.data?.error_code || 0,
            title: e?.response?.data?.message || 'Ошибка при сверке клиента',
          }
          this.onError(error)
          this.$store.commit('flatForm/SET_CHECK_OWNER', false);
          this.$store.commit('flatForm/SET_OWNER_CHECK_MESSAGE', e?.response?.data?.message);
        }
      }
    }
  },
  watch: {
    ownerIsHolder: {
      handler(isMatch) {
        if(!isMatch) {
          const item = { uuid: uuidv4() }
          this.changePropertyOwner({ item })
        } else {
          this.changePropertyOwner( { item: null })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
