<template>
  <div class="form-group">
    <ul class="input-list">
      <li class="input-list-item" style="background: #ffffff;box-shadow: 0 12px 16px rgb(0 0 0 / 5%); border-radius: 12px; padding: 0.4rem 1rem; margin: 1rem 0;">
        <div>
          <span class="primary-text">{{ clientInfo.fullName }}</span>
          <span class="secondary-text">{{ clientInfo.iin }}</span>
        </div>
        <icon v-if="clientInfo.checked" icon-name="success-icon" style="width: 1.5rem; height: 1.5rem; fill: #00805f;"/>
      </li>
    </ul>
    <div v-if="!clientInfo.checked">
      <search-select
          :options="documents"
          :is-block="true"
          :reduce="item => item.value"
          label="name"
          name="selected_doc"
          rules="required"
          :placeholder="$t('docType')"
          v-model="form.doc_type"
      />

      <input-field
          :label="$t('docNumber')"
          :is-block="true"
          field-type="tel"
          rules="required"
          name="doc_number"
          v-model="form.doc_number"
      />

      <select-date
          v-model="form.doc_issue_date"
          :is-block="true"
          rules="required"
          name="doc_issue_date"
          :title="$t('docGiveDate')"
      />
    </div>

    <div v-if="clientInfo.checkMessage" style="color: red">
      {{ clientInfo.checkMessage }}
    </div>
  </div>
<!--  <div class="draft-driver row">-->
<!--    <div class="col-12 driver-info" v-if="!clientInfo.checked">-->
<!--      <input-->
<!--          type="text"-->
<!--          :value="clientInfo['iin']"-->
<!--          :class="{-->
<!--          'labeled-input': true,-->
<!--          checked: clientInfo['checked'],-->
<!--          error: errorMessage !== '',-->
<!--        }"-->
<!--          disabled-->
<!--      />-->
<!--      <label class="input-label active">{{ clientInfo.fullName }}</label>-->
<!--      <i class="checked-icon"></i>-->
<!--    </div>-->
<!--    <transition name="expand">-->



<!--      <div class="col-12" v-if="clientInfo.clientDocuments && !clientInfo['checked']">-->
<!--        <div-->
<!--            :class="{-->
<!--            document: true,-->
<!--            'select-document': true,-->
<!--            error: errorMessage !== '',-->
<!--          }"-->
<!--        >-->
<!--          <span-->
<!--              :class="{-->
<!--              select: true,-->
<!--              'labeled-input': true,-->
<!--              'd-flex': true,-->
<!--              'align-items-center': true,-->
<!--              active: docTypeSelectorShown,-->
<!--            }"-->
<!--              @click="docTypeSelectorShown = !docTypeSelectorShown"-->
<!--          >{{ edidableDocumentLabel(edidableDocumentType) }}<i class="selector"></i></span>-->
<!--          <transition name="slide">-->
<!--            <ul class="dropdown-container" v-show="docTypeSelectorShown">-->
<!--              <li @click="edidableDocumentType = 'IC'; docTypeSelectorShown = false;">-->
<!--                {{ $t("docStep.labels.ic") }}-->
<!--              </li>-->
<!--              <li @click="edidableDocumentType = 'PS'; docTypeSelectorShown = false;">-->
<!--                {{ $t("docStep.labels.ps") }}-->
<!--              </li>-->
<!--              <li @click="edidableDocumentType = 'RPF'; docTypeSelectorShown = false;">-->
<!--                {{ $t("docStep.labels.rpf") }}-->
<!--              </li>-->
<!--            </ul>-->
<!--          </transition>-->
<!--        </div>-->
<!--        <div :class="{ document: true, error: errorMessage !== '' }">-->
<!--          <input-->
<!--              type="text"-->
<!--              :disabled="clientInfo['checked']"-->
<!--              :placeholder="-->
<!--              edidableDocument(edidableDocumentType)['docType'] === 'IC'-->
<!--                ? '000000000'-->
<!--                : edidableDocument(edidableDocumentType)['docType'] === 'PS'-->
<!--                ? 'N000000000'-->
<!--                : edidableDocument(edidableDocumentType)['docType'] === 'RPF'-->
<!--                ? '000000000'-->
<!--                : ''-->
<!--            "-->
<!--              v-model="edidableDocument(edidableDocumentType)['docNumber']"-->
<!--              :class="{ 'labeled-input': true, 'first-doc': true }"-->
<!--          />-->
<!--          <label :class="{ 'input-label': true, active: true }">{{-->
<!--              edidableDocumentLabel(edidableDocumentType)-->
<!--            }}</label>-->
<!--          <input-->
<!--              :disabled="clientInfo['checked']"-->
<!--              type="text"-->
<!--              inputmode="numeric"-->
<!--              class="labeled-input doc pad-right"-->
<!--              v-mask="'##.##.####'"-->
<!--              placeholder="дд.мм.гггг"-->
<!--              v-model="edidableDocument(edidableDocumentType)['docIssueDate']"-->
<!--              autocomplete="off"-->
<!--          />-->
<!--          <label :class="{ 'input-label': true, active: true, date: true }">{{-->
<!--              $t("docStep.labels.date")-->
<!--            }}</label>-->
<!--        </div>-->
<!--      </div>-->
<!--    </transition>-->
<!--    <div class="col-12">-->
<!--      <div class="white-back">-->
<!--        <button :class="{ btn: true, ok: true }" @click="setPeople" v-if="!clientInfo['checked']">-->
<!--          ОК-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-12" v-if="errorMessage != ''">-->
<!--      <label class="error-alert">{{ errorMessage }}</label>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import SearchSelect from "./SearchSelect";
import InputField from "./InputField";
import SelectDate from "./SelectDate";
import moment from "moment";

export default {
  name: "ClientDocument",
  components: {SelectDate, InputField, SearchSelect},
  props: {
    clientInfo: Object,
    clientIndex: Number
  },
  data() {
    return {
      errorMessage: "",
      docTypeSelectorShown: false,
      discountDocTypeSelectorShown: false,
      edidableDocumentType: "IC",
      edidableDiscountDocType: "PC",
      errorCount: 0,
      documents: [
        {name: this.$i18n.t('kazID'), value: 'IC'},
        {name: this.$i18n.t('passport'), value: 'PS'},
        {name: this.$i18n.t('nonResidentPermit'), value: 'RPF'},
        {name: this.$i18n.t('driverLicense'), value: 'DL'},
      ],
      form: {
        index: this.clientIndex,
        doc_type: "IC",
        doc_issue_date: null,
        doc_number: null
      }
    };
  },
  watch: {
    'form.doc_type': {
      immediate: true,
      handler() {
        const docInfo = this.clientInfo.clientDocuments.find(doc => doc.docType === this.form.doc_type)

        this.form.doc_issue_date = moment(docInfo?.docIssueDate).format('YYYY-MM-DD')
        this.form.doc_number = docInfo?.docNumber
      }
    },
    'form.doc_issue_date': {
      handler() {
        this.$emit('changed', this.form);
      }
    },
    'form.doc_number': {
      handler() {
        this.$emit('changed', this.form);
      }
    },
  },
  methods: {
    async setPeople() {
      try {
        this.errorMessage = "";
        this.$store.dispatch("SET_ISLOADING", true);
        if (this.clientInfo["mainClient"]) {
          this.clientInfo.email = this.$store.getters.EMAIL;
          this.clientInfo.phoneNumber = JSON.parse(
              JSON.stringify(this.$store.getters.ORDERPHONE)
          ).replace(/[()+\- ]/g, "");
        } else {
          this.clientInfo.email = "";
        }
        this.clientInfo["clientDocuments"].forEach((doc, key) => {
          if (
              (doc["docIssueDate"] === "" || doc["docNumber"] === "") &&
              doc["docType"] != this.edidableDocumentType &&
              doc["docType"] != this.edidableDiscountDocType &&
              doc["docType"] != "DL"
          ) {
            this.clientInfo["clientDocuments"].splice(key, 1);
          }
        });
        var clientData = JSON.parse(JSON.stringify(this.clientInfo));
        clientData["clientDocuments"].forEach((doc, key) => {
          if (
              (doc["docIssueDate"] === "" || doc["docNumber"] === "") &&
              (doc["docType"] === this.edidableDocumentType ||
                  doc["docType"] === "DL" ||
                  doc["docType"] === this.edidableDiscountDocType)
          ) {
            let docDataError = new Error();
            docDataError.message = "Пожалуйста заполните все данные";
            docDataError.name = "unimportant";
            throw docDataError;
          } else {
            if (doc["docNumber"].length < 6) {
              let docLengthError = new Error();
              docLengthError.message =
                  "Минимальная допустимая длина номера документа - 6 символов";
              docLengthError.name = "unimportant";
              throw docLengthError;
            }
            let parsedDate = doc["docIssueDate"].split(".");
            var newDate = new Date(
                parsedDate[2] + "-" + parsedDate[1] + "-" + parsedDate[0]
            );
            clientData["clientDocuments"][key]["docIssueDate"] = newDate.toISOString();
          }
        });
        const { data } = await this.$axios.post("/api/SetNewClient", clientData);
        if (data["statusCode"]) {
          throw new Error(data["value"]);
        } else {
          if (data["verifyType"] != 1) {
            this.clientInfo["getPeopleId"] = data["getPeopleId"];
            if (!this.esbdStatus) {
              this.clientInfo["checked"] = true;
            }
            this.$store.dispatch("SET_DOCS_OFFLINE", false);
            this.$store.dispatch("SET_ISLOADING", false);

          }
          this.$store.getters.CLIENTS[this.clientIndex]["getPeopleId"] =
              data["getPeopleId"];
          this.clientInfo["checked"] = true;
          this.$store.dispatch("SET_ISLOADING", false);
        }
      } catch (err) {
        this.$store.dispatch("SET_ISLOADING", false);
        this.$store.dispatch("SET_ADDBREADCRUMB", {
          error: err,
          send: true,
          stage_id: this.$store.getters.STAGE_ID,
          error_type: "Не удалось сверить/загрузить документы (/SetNewClient)",
          type: "error",
        });

        this.clientInfo["checked"] = false;
        this.errorMessage = err.message;
      }
    },
  },
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0s ease-out;
}

.slide-enter,
.slide-leave-to {
  height: 0px;
}
</style>
