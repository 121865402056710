<template>
  <div class="primary-block d-flex align-items-center">
    <div class="icon-box me-3">
      <icon icon-name="remove-icon"/>
    </div>
    <span class="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "RemoveButton",
  props: {
    text: String
  }
}
</script>

<style scoped>
.text {
  font-weight: 500;
  color: #FD2D39;
}
</style>