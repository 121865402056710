import { render, staticRenderFns } from "./CheckboxList.vue?vue&type=template&id=89e3b538&scoped=true"
import script from "./CheckboxList.vue?vue&type=script&lang=js"
export * from "./CheckboxList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89e3b538",
  null
  
)

export default component.exports