<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ invalid, errors }">
      <div>
        <input-field
            :label="$t('phoneNumber')"
            v-model="phone_number"
            field-type="tel"
            :is-block="true"
            rules="required|numeric|min:10"
            name="phone"
            mask="+7(###) ###-##-##"
            :disabled="true"
        />
      </div>

      <PersonInfo v-if="holder" :value="holder" @changed="changeSubsHolder" :key="holder.uuid" @error="onError"/>

     <!--  <radio-input-list
          :read-more="false"
          :options="monthsOptions"
          radio-name="periods"
      /> -->

      <div v-if="holder && holder.individual_id_number && holder.individual_id_number.length === 12">
<!--        <checkbox-list v-if="false" :options="ownerIsHolderProp" name="owner_is_holder" v-model="ownerIsHolder"/>-->

        <PersonInfo v-if="!ownerIsHolder" :value="propertyOwner" @changed="changePropertyOwner" @error="onError"/>

        <div class="insured-property-select-wrap" v-if="holder.individual_id_number && (ownerIsHolder || (!ownerIsHolder && propertyOwner && propertyOwner.individual_id_number))">
          <InsuredProperty :value="insuredProperty" @changed="changeInsuredProperty" :need-flat="false"/>

<!--          <select-date-->
<!--             :value="startDate"-->
<!--             @input="changeStartDate"-->
<!--             :title="$t('policyStartDate')"-->
<!--             rules="required"-->
<!--             name="policy_date"-->
<!--             :until="initialDate"-->
<!--             :is-disabled-before-today="true"-->
<!--             :is-block="true"-->
<!--          />-->
        </div>
      </div>
     
    <!--  <select-rate
      :title="'Тарифы'"
      :rates="rates"
      @change_input="changeRate"/> -->

      <template v-if="loadingActive">
        <div class="image-card card py-4 px-3" v-for="item in 1" :key="item" style="margin-bottom: 16px;">
          <div class="">
            <h3 class="title skeleton skeleton-text w-75"></h3>
            <h3 class="title skeleton skeleton-text w-50"></h3>
            <h3 class="title skeleton skeleton-text w-25"></h3>
            <div class="gray-btn skeleton w-50"></div>
          </div>
        </div>
      </template>

    <select-tariff v-else :tariffs="tariffs" :title="$t('rate')" @change_input="changeTariffCalc"></select-tariff>
      <div class="d-flex">
        <ValidationProvider :rules="{ required: { allowFalse: false } }" name="terms">
          <input type="checkbox" id="terms" class="me-4" name="terms" v-model="i_agree">
        </ValidationProvider>
        <label for="terms" class="terms-text">Я принимаю условия <span style="color: #2aa65c;" @click.prevent="showTermsModal">пользовательского соглашения</span> и даю свое согласие на сбор и обработку персональных данных</label>
      </div>

      <div class="final-amount-wrapper" v-if="rates_index != null">
       <final-amount
               v-if="i_agree"
              :amount="finalAmountObj.amount"
              :discount-value="finalAmountObj.bonus_value"
              :fix-sum="finalAmountObj.fix_sum"
              :rate-total="true"
              :invalid="invalid"
              :is-sub="true"
              @submit="onSubmit"
          />
    </div>

    </ValidationObserver>
    <swipe-modal
        v-model="isModal"
        contents-height="50vh"
        border-top-radius="16px"
        :persistent="true"
        backgroundColor="#F7F7F7"
        tip-color="#c8c8c8"
        contents-color="#F7F7F7"
    >
      <sos-feedback></sos-feedback>
    </swipe-modal>
  </div>
</template>

<script>
import InputField from "@/components/BuyPolicy/InputField";
import SelectDate from "../BuyPolicy/SelectDate";
import {mapActions, mapGetters} from "vuex";
import CheckboxList from "../BuyPolicy/CheckboxList";
import FinalAmount from "../BuyPolicy/FinalAmount";
import {myHouseSubscriptionCalculation} from "../../api/calculations";
import ePayPayment from "../../mixin/ePayPayment";
import eventLogger from '../../services/eventLogger';
import PersonInfo from "../PersonInfo";
import RadioInputList from "../BuyPolicy/RadioInputList";
import InsuredProperty from "../InsuredProperty";
import moment from "moment";
import {uuidv4} from "../../plugins/Helpers";
import commonFormData from "../../mixin/commonFormData";
import redmine from "../../mixin/redmine";
import BoxProductBonuses from "../BuyPolicy/BoxProductBonuses";
import SelectRate from "../BuyPolicy/SelectRate";
import SelectTariff from "@/components/BuyPolicy/SelectTariff";
import subscriptionForm from "../../mixin/subscriptionForm";
import TermsModal from "../Modals/TermsModal";
import swipeModal from "@takuma-ru/vue-swipe-modal";
import SosFeedback from "../../views/SosFeedback";

export default {
  name: "houseSubs",
  mixins: [ePayPayment, commonFormData, redmine, subscriptionForm],
  components: {
    BoxProductBonuses,
    InsuredProperty,
    PersonInfo,
    CheckboxList,
    InputField,
    SelectDate,
    FinalAmount,
    RadioInputList,
    SelectRate,
    SelectTariff,
    swipeModal,
    SosFeedback
  },
  mounted(){
    //this.changeRate(this.rates_index);
    this.getFullTariff();
  },
  data() {
    return {
      isModal:false,
      i_agree:false,
      tariffUrl: 'subscription/house-tariffs',
      loadingActive: true,
      currentTariff: 'Легкая',
      tariffs: [],
      rates_index: 0,
      rates: [
        {name: 'Легкий', price: 390, lists: [{ name: 'Выплата Вам', price: '1 000 000' }] },
        {name: 'Стандартный', price: 790, lists: [{ name: 'Выплата Вам', price: '2 000 000' }] },
        {name: 'Оптимальный', price: 1190, lists: [{ name: 'Выплата Вам', price: '3 000 000' }] },
      ],
      rates_calc: [
       {amount: 1170, bonus_title: 'Скидка на первые три месяца', bonus_value: 900, total_title: 'Итого  на три месяца',fix_sum: 270, product_name: 'за три месяца', label_id: 'LIGHT' },
        {amount: 2370, bonus_title: 'Скидка на первые три месяца', bonus_value: 1800, total_title: 'Итого  на три месяца',fix_sum: 570, product_name: 'за три месяца' , label_id: 'STANDARD'  },
        {amount: 3570, bonus_title: 'Скидка на первые три месяца', bonus_value: 2700, total_title: 'Итого  на три месяца',fix_sum: 870, product_name: 'за три месяца',  label_id: 'OPTY'},
      ],
      finalAmountObj:{},
      amount: 15000,
      monthsOptions: [{ name: this.$i18n.t('month12'), value: 12 }],
      ownerIsHolderProp: [
        {
          name: this.$i18n.t('insuredAndPolicyHolderMatch'),
          value: this.ownerIsHolder,
        }
      ],
    }
  },
  async created() {
    eventLogger('view_house_insurance_subscription')
    const { getUnionAddressDictionary, $loading } = this;

    const loader = $loading.show();

    // this.changeTariff('Легкая');
    
    try{
      await getUnionAddressDictionary();
    } catch (err) {
      this.isModal = true
      this.onError({
        title: `Ошибка при получение данных справочника`,
        message: (err?.response?.data?.message || err?.message),
        code: err?.response?.data?.error_code || 0
      })
    }

    loader.hide()
  },
  computed: {
    ...mapGetters({
      holder: 'houseSubs/holder',
      propertyOwner: 'houseSubs/propertyOwner',
      insuredProperty: 'houseSubs/insuredProperty',
      startDate: "houseSubs/startDate",
      form: 'houseSubs/finalSubscriptionFormValue'
    }),
    finalAmount() {
      return this.amount
    },
    ownerIsHolder: {
      get() {
        return this.$store.getters['houseSubs/ownerIsHolder'];
      },
      set(val) {
        this.changeOwnerIsHolder(val);
      }
    },
    clientsChecked() {
      let checked = true;

      if(this.holder.checked === false) {
        checked = false
      }

      if(this.ownerIsHolder === false && this.propertyOwner.checked === false) {
        checked = false
      }

      return checked
    }
  },
  methods: {
    ...mapActions({
      changeStartDate: 'houseSubs/changeStartDate',
      changeOwnerIsHolder: 'houseSubs/changeOwnerIsHolder',
      changeHolder: 'houseSubs/changeHolder',
      changePropertyOwner: 'houseSubs/changePropertyOwner',
      changeInsuredProperty: 'houseSubs/changeInsuredProperty',
      resetForm: 'houseSubs/resetForm',
      getUnionAddressDictionary: 'dictionary/getUnionAddressDictionary',
      changeSelectedBonus: 'houseSubs/changeSelectedBonus',
      changeTariff: 'houseSubs/changeTariff',
    }),


    async calculation() {
      const data =  await myHouseSubscriptionCalculation(this.form)
      // Логирование
      await eventLogger('select_payment_house_subscription', {payment_sum: this.amount})
      return data
    },

    async setDefaultInfo() {
      const { authUser, holder } = this;

      // Если нет держателя то записываем текущего пользователя
      if(holder === null || !this.holder.individual_id_number) {
        this.changeHolder({item: {
          uuid: uuidv4(),
          individual_id_number: authUser.individual_id_number
        }})
      }

      this.setInitialDate(moment().add(1, "days").format("YYYY-MM-DD"))
    },
    showTermsModal() {
      this.$modal.show(TermsModal, {}, this.$modalConfig);
    },
    async setAgreementInfo() {
      this.agreement_id = this.$route.query.agreement_id;

      const { data } = await window.axios.get(`policies/${this.agreement_id}`);

      const object = data?.objects[0];

      await this.$store.commit("houseSubs/SET_HOLDER", {
        uuid: uuidv4(),
        individual_id_number: data.holder.bin
      });

      this.setInitialDate(data.endDate.date)

      if(object) {
        const foundCity = this.listCities.find(item => item.city === object.city);

        if (foundCity) {
          this.changeInsuredProperty({
            country: "KAZ",
            state: String(foundCity?.state),
            region: String(foundCity?.region),
            city: String(foundCity?.city),
            street_name: object.street_name,
            street_no: object.blockNumber,
            flat_no: object.apartmentNumber,
          })
        }
      }
    },

    async checkClients() {
      this.$store.commit('houseSubs/SET_HOLDER_CHECK_MESSAGE', null);

      try {
        await window.axios.post(`/verified-client-csdb`, this.holder)

        this.$store.commit('houseSubs/SET_CHECK_HOLDER', true);
      } catch (e) {
        this.isModal = true
        const error = {
          message: e?.response?.data?.message || 'Ошибка при сверке клиента',
          code: e?.response?.data?.error_code || 0,
          title: e?.response?.data?.message || 'Ошибка при сверке клиента',
        }
        this.onError(error)
        this.$store.commit('houseSubs/SET_CHECK_HOLDER', false);
        this.$store.commit('houseSubs/SET_HOLDER_CHECK_MESSAGE', e?.response?.data?.message);
      }

      if(!this.ownerIsHolder) {
        this.$store.commit('houseSubs/SET_OWNER_CHECK_MESSAGE', null);
        try {
          await window.axios.post(`/verified-client-csdb`, this.propertyOwner)

          this.$store.commit('houseSubs/SET_CHECK_OWNER', true);
        } catch (e) {
          this.isModal = true
          const error = {
            message: e?.response?.data?.message || 'Ошибка при сверке клиента',
            code: e?.response?.data?.error_code || 0,
            title: e?.response?.data?.message || 'Ошибка при сверке клиента',
          }
          this.onError(error)
          await eventLogger('house_fail')
          this.$store.commit('houseSubs/SET_CHECK_OWNER', false);
          this.$store.commit('houseSubs/SET_OWNER_CHECK_MESSAGE', e?.response?.data?.message);
        }
      }
    }
  },
  watch: {
    ownerIsHolder: {
      handler(isMatch) {
        if(!isMatch) {
          const item = { uuid: uuidv4() }
          this.changePropertyOwner({ item })
        } else {
          this.changePropertyOwner( { item: null })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
