<template>
  <div class="wrapper">
    <v-dialog />
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <input-field
          v-model="form.phone_number"
          :addition="$t('cantChangeNumber')"
          :disabled="true"
          :is-block="true"
          :label="$t('phoneNumber')"
          field-type="tel"
          mask="+7(###) ###-##-##"
          name="phone"
          rules="required|numeric|min:10"
      />

      <div>
        <radio-input-list
            v-if="formStepValue > 0"
            :addition="travelTypeAdditionText"
            :options="travel_types"
            :title="$t('typeOfTrip')"
            :value="form.is_multiple"
            radio-name="type-of-travel"
            @input="changeTravelType"
        />

        <search-select
            v-if="!travelIsMultiple"
            ref="cover_types"
            v-model="packet"
            :addition="singleTravelTypeText"
            :is-block="true"
            :options="programs"
            :placeholder="$t('selectPackage')"
            :reduce="p => p.id"
            label="name"
            name="packet"
            rules="required"
            track-by="value"
        />

        <template v-if="formStepValue > 1">
          <add-country
              :addBtnDisabled="countryAddBtnDisabled"
              :button-title="$t('addCountry')"
              :countries="form.countries"
              :countriesList="remainingCountries"
              @delete="deleteCountry"
              @input="addCountry"
          />
        </template>

        <template v-if="formStepValue > 2">
          <search-select
              v-model="insuredSum"
              :is-block="true"
              :options="amounts"
              :placeholder="$t('selectInsuranceSum')"
              label="name"
              name="insuranceSum"
              rules="required"
              track-by="value"
          />
        </template>


        <template v-if="formStepValue > 3">
          <select-date
              v-model="form.start_date"
              :is-block="true"
              :is-disabled-before-today="true"
              :until="untilTomorrow"
              :title="$t('departureDate')"
          />
          <select-date
              v-if="!travelIsEducational && !travelIsMultiple"
              v-model="form.end_date"
              :is-block="true"
              :is-disabled-before-today="true"
              :title="$t('arrivalDate')"
              :until="minEndDate"
          />
        </template>

        <search-select
            v-if="(travelIsEducational && form.cover_value) || travelIsMultiple"
            v-model="form.period"
            :is-block="true"
            :options="insurancePeriods"
            :placeholder="$t('insurancePeriod')"
            :reduce="ip => ip.value"
            label="name"
            name="insurancePeriods"
            rules="required"
            track-by="value"
        />
        <search-select
            v-if="form.period && travelIsMultiple"
            v-model="form.duration"
            :is-block="true"
            :options="insuranceDurations"
            :placeholder="$t('tripDuration')"
            :reduce="d => d.value"
            label="name"
            name="durationOfTravel"
            rules="required"
            track-by="value"
        />
        <search-select
            v-if="showFranchiseField"
            v-model="form.franchise"
            :is-block="true"
            :options="franchiseOptions"
            :placeholder="$t('franchise')"
            :reduce="f => f.value"
            label="name"
            name="franchiseOption"
            rules="required"
            track-by="value"
        />

        <single-check-box
            v-if="((form.start_date && form.end_date) || (form.start_date && form.period)) && travelIsEducational"
            :label="$t('isStudentAthlete')"
            v-model="isAthlete"
        />
        <search-select
            v-if="isAthlete || travelIsAthletic"
            v-model="form.sport"
            :is-block="true"
            :options="sports"
            :placeholder="$t('sportType')"
            :reduce="s => s.value"
            label="name"
            track-by="value"
        />
        <single-check-box
            v-if="(form.start_date && form.end_date) || (form.start_date && form.period)"
            :label="$t('alreadyInCountryOfTempResidence')"
            v-model="alreadyInCountry"
        />

        <div v-if="((form.start_date && form.end_date) || (form.start_date && form.period)) && alreadyInCountry" class="addition-invalid">
          <icon class="me-2" icon-name="read-info-icon"></icon>
          <span>{{ $t('insuranceCountryWarning') }}</span>
        </div>

        <template v-if="formStepValue > 5">
          <div>
            <TouristInfo :value="form.holder" @changed="changeHolder" :key="form.holder.uuid"/>

<!--            <single-check-box :label="$t('policyHolderTheInsured')" v-model="form.owner_is_holder"/>-->
          </div>
          <template v-for="(insured, index) in form.insureds">
            <TouristInfo
                :key="`insured_${insured.uuid}`"
                :can-delete="!!form.insureds.length"
                :index="index"
                :title="touristInfoTitle(insured.uuid)"
                :value="insured"
                rules="required"
                @changed="changeTourist"
                @error="onError"
                @removeItem="removeTourist"
            />
          </template>

          <div
              v-if="canAddTourist"
              class="primary-block"
              @click="addTourist"
          >
            <div class="input-add-id">
              <div class="icon-box">
                <icon icon-name="plus-icon"></icon>
              </div>
              <span class="text">{{ $t('addTourist') }}</span>
            </div>
          </div>
        </template>
        <box-product-bonuses v-if="formStepValue >= 7" :available-products="availableBonuses" v-model="form.bonus_id"/>
        <div v-if="formStepValue >= 7" class="final-amount-wrapper">
          <final-amount
              :bonus-title="bonusTitle"
              :bonus-value="bonusValue"
              :amount="amount"
              :amount-loaded="amountLoaded"
              :fix-sum="amount"
              :insured-sum="insuredSum.name"
              :invalid="invalid"
              @submit="onSubmit"
          />
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import SearchSelect from "../../BuyPolicy/SearchSelect";
import SelectDate from "../../BuyPolicy/SelectDate";
import RadioInputList from "../../BuyPolicy/RadioInputList";
import InputField from "../../BuyPolicy/InputField";
import SingleCheckBox from "../../BuyPolicy/SingleCheckBox";
import FinalAmount from "../../BuyPolicy/FinalAmount";
import AddCountry from "../../BuyPolicy/AddCountry";
import {mapActions, mapGetters} from "vuex";
import {travelerCalculation} from "../../../api/calculations";
import eventLogger from "../../../services/eventLogger";
import ePayPayment from "../../../mixin/ePayPayment";
import {uuidv4} from "../../../plugins/Helpers";
import redmine from "../../../mixin/redmine";
import travelForm from "../../../mixin/travelForm";
import TouristInfo from "../../TouristInfo";
import _ from "lodash";
import BoxProductBonuses from "../../BuyPolicy/BoxProductBonuses";

export default {
  name: "CovidProtectForm",
  mixins: [ePayPayment, redmine, travelForm],
  components: {
    BoxProductBonuses,
    SingleCheckBox,
    TouristInfo,
    SearchSelect,
    SelectDate,
    RadioInputList,
    InputField,
    FinalAmount,
    AddCountry,
  },
  data() {
    return {
      amount: 0,
      packet: null,
      isAthlete: false,
      alreadyInCountry: false,
      form: {
        draft_id: uuidv4(),
        phone_number: null,
        insurance_type: null,
        owner_is_holder: true,
        holder: {
          uuid: null,
          individual_id_number: null,
          gender: null,
          doc_type: null,
          doc_number: null,
          doc_issue_date: null,
          address_region: null,
          address_state: null,
          address_city: null,
          address_street_full: null,
          is_foreigner: false,
          birth_date: null,
          last_name: null,
          first_name: null,
          full_name: null,
          documents: [],
          error: null,
          country: null,
        },
        is_multiple: false,
        cover_value: null,
        cover_type: null,
        start_date: null,
        end_date: null,
        period: null,
        duration: null,
        sport: null,
        currency: null,
        franchise: null,
        countries: [],
        insureds: [],
        bonus_id: null
      },
      amountWrapperLoaded: false,
      amountLoaded: false,
    }
  },
  created() {
    eventLogger('view_traveler_screen');

    this.debouncedSaveDraft = _.debounce(this.saveDraft, 600)
    this.debouncedGetCalculation = _.debounce(this.getCalculation, 600)
  },
  async mounted() {
    const loading = this.$loading.show();

    try {
      await this.setDefaultInfo();
      await this.getCityList()
      await this.getTravelerDictionary(this.product.id);
    } catch (e) {
      const {data} = e.response;
      await this.sendErrorsToRedmine(data);
    } finally {
      loading.hide()
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'authUser',
      listCities: 'dictionary/cityList',
      travelerDictionary: 'dictionary/traveler',
      product: 'INSURANCE_PRODUCT'
    }),
    insuranceType() {
      return this?.product?.insurance_type
    },
    travelIsEducational() {
      return Number(this.packet) === 11
    },
    travelIsAthletic() {
      return Number(this.packet) === 10
    },
    programs() {
      return this.packets.filter((f) => f.code !== "ESP_OL_AC" && f.code !== 'MUL_OL_AC');
    },
    formStepValue() {
      const { form, isAthlete, alreadyInCountry } = this
      const { cover_type, countries, cover_value, end_date, period, sport,  holder, insureds, duration } = form
      let step = 1;

      if (!cover_type) { return step; }
      step = 2;

      if (countries.length === 0) { return step; }
      if (countries.some(country => !country.code)) { return step }
      step = 3

      if (!cover_value) { return step; }
      step = 4

      if (!end_date) { return step }
      step = 5

      if (["ESP_OL_AC", "SPR_OL_AC"].includes(cover_type) && !sport) { return step }
      if (cover_type === "MUL_OL_AC" && !duration) { return step }
      if (["EDU_OL_AC", "ESP_OL_AC"].includes(cover_type) && !period) { return step }
      if (cover_type === "ESP_OL_AC" && !isAthlete) { return step }

      if(alreadyInCountry) { return step }
      // if (this.showFranchiseField) {
      //   if (!this.fields.franchise) {
      //     if (showErrors) {
      //       this.errors.franchise = true;
      //
      //       if (!error) {
      //         this.$refs.franchise.scrollToThis();
      //       }
      //     }
      //
      //     error = true;
      //   }
      // }
      step = 6;

      // let isFirstTouristAdult = false;
      // this.touristsFound = true;
      let holderIsValid = true;

      if (holder.foreigner === 'N') {
        holderIsValid = this.validateResident(holder);
      } else {
        holderIsValid = this.validateForeigner(holder);
      }

      if (!holderIsValid) { return step }

      let touristsValid =  true;

      for (let i = 0; i < insureds.length; i++) {
        const insured = insureds[i]

        touristsValid = insured.foreigner === 'N' ? this.validateResident(insured) : this.validateForeigner(insured)

        if(!touristsValid) { return step }
      }

      step = 7
      // for (let index in this.computedTourists) {
      //   const item = this.computedTourists[index];

      //Vue.set(this.touristFormStep, index, 1);

      // if (!item.isForeigner) {
      //   if (item.bin.length != 12) {
      //     if (showErrors) {
      //       this.errors.tourists[index].bin = true;
      //
      //       if (!error) {
      //         this.$refs.tourists_bin[index].scrollToThis();
      //       }
      //     }
      //
      //     error = true;
      //   }
      //
      //   if (item.found === false) {
      //     if (!item.originalLastName) {
      //       if (showErrors) {
      //         this.errors.tourists[index].originalLastName = true;
      //
      //         if (!error) {
      //           this.$refs.tourists_originalLastName[index].scrollTo();
      //         }
      //       }
      //
      //       error = true;
      //     }
      //
      //     if (!item.originalFirstName) {
      //       if (showErrors) {
      //         this.errors.tourists[index].originalFirstName = true;
      //
      //         if (!error) {
      //           this.$refs.tourists_originalFirstName[index].scrollTo();
      //         }
      //       }
      //
      //       error = true;
      //     }
      //
      //     if (!item.patronymic) {
      //       if (showErrors) {
      //         this.errors.tourists[index].patronymic = true;
      //
      //         if (!error) {
      //           this.$refs.tourists_patronymic[
      //               index
      //               ].scrollToThis();
      //         }
      //       }
      //
      //       error = true;
      //     }
      //
      //     if (!item.birthDate) {
      //       if (showErrors) {
      //         this.errors.tourists[index].birthDate = true;
      //
      //         if (!error) {
      //           this.$refs.tourists_birthDate[
      //               index
      //               ].scrollToThis();
      //         }
      //       }
      //
      //       error = true;
      //     }
      //
      //     if (!item.sex) {
      //       if (showErrors) {
      //         this.errors.tourists[index].sex = true;
      //
      //         if (!error) {
      //           this.$refs.tourists_sex[
      //               index
      //               ].scrollToThis();
      //         }
      //       }
      //
      //       error = true;
      //     }
      //
      //     this.touristsFound = false;
      //     // error = true;
      //   } else if (item.found === null) {
      //     this.touristsFound = null;
      //     error = true;
      //   }
      // } else {
      //   if (!item.country) {
      //     if (showErrors) {
      //       this.errors.tourists[index].bin = true;
      //
      //       if (!error) {
      //         this.$refs.tourists_country[
      //             index
      //             ].scrollToThis();
      //       }
      //     }
      //
      //     error = true;
      //     item.found = false;
      //   } else {
      //     item.found = true;
      //   }
      // }

      // if (!item.birthDate) {
      //   if (showErrors) {
      //     this.errors.tourists[index].birthDate = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_birthDate[index].scrollToThis();
      //     }
      //   }
      //
      //   error = true;
      // } else {
      //   const ageDifMs = Date.now() - item.birthDate.getTime();
      //   const ageDate = new Date(ageDifMs);
      //   const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      //
      //   if (age >= 75) {
      //     item.ageError = true;
      //     this.errors.tourists[index].birthDate = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_birthDate[index].scrollToThis();
      //     }
      //
      //     this.$root.openDialog(this.$v("STR_AGE_RESTRICTION2"));
      //
      //     error = true;
      //     continue;
      //   }
      //
      //   if (index == 0 && age >= 18) {
      //     isFirstTouristAdult = true;
      //   }
      //
      //   if (age < 16) {
      //     item.childMessage = true;
      //     if (index > 0 && isFirstTouristAdult) {
      //       item.childAgeError = false;
      //     } else {
      //       item.childAgeError = true;
      //
      //       this.errors.tourists[index].birthDate = true;
      //
      //       if (!error) {
      //         this.$refs.tourists_birthDate[
      //             index
      //             ].scrollToThis();
      //       }
      //
      //       error = true;
      //     }
      //   } else {
      //     item.childMessage = false;
      //     item.childAgeError = false;
      //   }
      // }

      // if (!item.firstName) {
      //   if (showErrors) {
      //     this.errors.tourists[index].firstName = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_firstName[index].scrollToThis();
      //     }
      //   }
      //
      //   error = true;
      // }

      // if (!item.lastName) {
      //   if (showErrors) {
      //     this.errors.tourists[index].lastName = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_lastName[index].scrollToThis();
      //     }
      //   }
      //
      //   error = true;
      // }

      // if (!item.city) {
      //   if (showErrors) {
      //     this.errors.tourists[index].city = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_city[index].scrollToThis();
      //     }
      //   }
      //
      //   error = true;
      // }

      // if (!item.address) {
      //   if (showErrors) {
      //     this.errors.tourists[index].address = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_address[index].scrollToThis();
      //     }
      //   }
      //
      //   error = true;
      // }

      // if (!item.docType) {
      //   error = true;
      //
      //   if (showErrors) {
      //     this.errors.tourists[index].docType = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_docType[index].scrollToThis();
      //     }
      //   }
      // }

      // if (!item.docNumber) {
      //   error = true;
      //
      //   if (showErrors) {
      //     this.errors.tourists[index].docNumber = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_docNumber[index].scrollToThis();
      //     }
      //   }
      // }

      // if (!item.docDate) {
      //   error = true;
      //
      //   if (showErrors) {
      //     this.errors.tourists[index].docDate = true;
      //
      //     if (!error) {
      //       this.$refs.tourists_docDate[index].scrollToThis();
      //     }
      //   }
      // }
      // }
      return step;
    },

    showFranchiseField() {
      return (
          this.form.cover_type === "MUL_OL_AC" && (this.form.currency !== "USD" || (this.form.period === 30 && this.form.duration === 10))
      );
    },
  },
  methods: {
    ...mapActions({
      getCityList: 'dictionary/getCityList',
      getPersonInfo: 'getPersonInfo',
      getTravelerDictionary: 'dictionary/getTravelerDictionary'
    }),
    async loading(callback) {
      const loader = this.$loading.show();
      await callback();
      loader.hide();
    },
    async setDefaultInfo(){
      const { authUser } = this;

      this.form.phone_number = `7${authUser.phone_number}`;

      if(!this.form.holder.individual_id_number) {
        this.form.holder.uuid = uuidv4();
        this.form.holder.individual_id_number = authUser.individual_id_number;
      }
    },
    resetAmount() {
      this.amount = 0;
    },
    resetPeriod() {
      this.form.period = null;
      this.form.duration = null;
      this.form.end_date = null;
    },
    resetSport() {
      this.isAthlete = false;
      this.form.sport = null;
    },
    async changeHolder({item}) {
      this.form.holder = item
    },
    async getCalculation() {
      this.amountLoaded = false;

      try {
        const calculation = await travelerCalculation(this.form);
        this.amount = calculation.amount;
      } catch (e) {
        this.amount = 0;
      }finally {
        this.amountLoaded = true;
      }
    },

    async onSubmit() {
      const loader = this.$loading.show();

      try {
        await this.checkHolder();
        await this.checkTourists();
      } catch (e) {
        return loader.hide();
      }

      try {
        if (!this.clientsChecked) {
          return loader.hide();
        }

        eventLogger('select_payment_travel', {payment_sum: this.amount});

        const data = await travelerCalculation(this.form);
        loader.hide();
        this.amount = data.amount;

        await this.$router.push({
          name: "insurance.products.checkout",
          params: {id: data.id},
        });


      } catch (e) {
        this.amount = 0;
      }
    },
    saveDraft() {
      window.axios.post('/policy-draft', this.form)
    }
  },
  watch: {
    insuranceType: {
      immediate: true,
      handler(value) {
        this.form.insurance_type = value
      }
    },
    "form.is_multiple": function (multipleTrip) {
      if (multipleTrip) {
        this.packet = this.packets.find(val => val.code === 'MUL_OL_AC').id
        this.form.cover_type = "MUL_OL_AC";
      } else {
        this.form.cover_type = null;
        this.packet = null
      }
    },
    "form.cover_type": function (coverType) {
      this.form.franchise = null;

      if (coverType === "EDU_OL_AC" && this.isAthlete) {
        this.form.cover_type = "ESP_OL_AC";
      } else {
        this.form.cover_type = coverType;
      }
    },
    "form.owner_is_holder": function(value) {
      if (!value && this.form.insureds.length === 0) {
        this.addTourist()
      }
    },
    "form.start_date": function (value) {
      if (this.travelIsMultiple || this.travelIsEducational) {
        this.form.end_date = this.addDays(value, this.form.period);
      }
    },
    "form.period": function (value) {
      if (this.travelIsMultiple || this.travelIsEducational) {
        this.form.end_date = this.addDays(this.form.start_date, value);
      }

      switch (String(value)) {
        case '180':
          this.form.duration = 45;
          break;
        case '90':
          this.form.duration = 30;
          break;
        case '30':
          this.form.duration = 10;
          break;
        default:
          this.form.duration = null;
      }
    },
    packet: function (value) {
      this.resetAmount();
      this.clearSelectedAmount();
      this.resetPeriod();
      this.resetSport();
      this.form.end_date = null;

      let cover_type = this.packets.find((packet) => packet.id === value);
      this.form.cover_type = cover_type ? cover_type.code : null;
    },
    isAthlete: function (state) {
      if (this.travelIsEducational) {
        this.form.cover_type = state ? 'SPR_OL_AC' : 'EDU_OL_AC';
      }
    },
    alreadyInCountry: function(value) {
      if(value) { this.resetAmount() }
    },
    form: {
      deep: true,
      async handler() {
        this.debouncedSaveDraft()

        if (this.insuranceCurrency === "USD" && (Number(this.form.period) !== 30 || Number(this.form.duration) !== 10)) {
          this.form.franchise = null;
        }

        if(this.formStepValue >= 7){
          await this.debouncedGetCalculation()
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
