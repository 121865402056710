<template>
  <div class="input-field" :class="{'block': isBlock}">
    <ul class="input-list">
      <li class="input-list-item" @click="isSelectOptionsShown = !isSelectOptionsShown">
        <p class="secondary-text mb-0">
          {{ title }}
          <span class="primary-text">{{ activeOption ? activeOption.name : '' }}</span>
        </p>
        <div>
          <icon icon-name="select-arrow-icon" class="arrow-down" :class="{'inverted': isSelectOptionsShown}"></icon>
        </div>
      </li>
    </ul>
    <ul class="options-list" :class="{'active': isSelectOptionsShown}">
      <template v-for="(item, index) in options">
        <li class="options-list-item" @click="selectOption(item)" v-if="activeOption !== item" :key="index">
          {{ item.name }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    options: {
      type: Array
    },
    title: {
      type: String
    },
    value: {
      type: Object
    },
    isBlock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSelectOptionsShown: false,
      activeOption: ''
    }
  },
  mounted() {
    if (this.options.length > 0) {
      this.activeOption = this.options[0]
    }
  },
  methods: {
    selectOption(item) {
      this.activeOption = item;
      this.isSelectOptionsShown = false;
    }
  },
  watch: {
    activeOption(option) {
      this.$emit('input', option);
    },
    value(newValue) {
      this.activeOption = newValue;
    },

  }
}
</script>

<style scoped>

</style>
